.editProfile {
  background-color: #fcf3fd;
  display: flex;
  /* align-items: self-start; */
  justify-content: center;
  flex-direction: column;
  padding: 0px 113px;
  position: relative;
}
.editProfile:before {
  background-color: #fcf3fd !important;
  left: -13px !important;
}
.adminProfileForm{
  width: 100%;
}
.adminProfileForm input {
  background-color: transparent !important;
  border: none;
  padding: 5px 0px;
  border-radius: 30px;
}

.formTabs{
  text-align: center;
  display: none;
}
.formTabs a {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.copyrightBottom{
  display: none;
}
.userproImg{
  max-width: 290px;
}
.userproImg img {
  object-fit: cover;
  height: 290px;
  width: 290px;
  border-radius: 20px;
}
.editProfileBox{
  height: 290px !important;
  width: 290px;
  border-radius: 20px;
  object-fit: cover;
}
.adminProfileForm input::placeholder {
  color: #2d2832;
}
.adminProfileForm input:focus {
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
}
.editIcon {
  position: relative;
  right: 7px;
}
.editProfileBtn {
  position: absolute;
  right: 160px;
  top: 50px;
}
.editProfileBtn a{
  color: #fff;
}

.discardBtn {
  position: absolute;
  right: 275px;
  top: 50px;
  background-color: #fff;
  color: #E9A5F3;
}
/*update profile*/
.updateProfileInner {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 59px;
    height: 59px;
    border-radius: 20px 0px 20px 0px;
    background: #E9A5F3;
    display: flex;
    align-items: center;
    justify-content: center;
}
.updateProfileInner img{
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.updateProfileImg{
    position: relative;
    max-width: 290.5px;
}
.updateEditIcon {
    position: absolute;
    bottom: 20px;
    right: 20px;
}
.updateFormGroup {
    background-color: #fff;
    padding: 17px 25px 17px 25px;
    border-radius: 20px;
    box-shadow: 0px 4px 25px 0px #E9A5F326;
    position: relative;
}
.updateFormGroup input{
    font-weight: 600;
    color: #d682e3a3 !important;
    background-color: transparent !important;
}
.updateFormGroup input::placeholder{
  font-size: 14px !important;
  font-weight: 600;
  
}
.updateFormGroup input.form-control::placeholder {
  color: #d682e3a3 !important;
}
.updateFormGroup label{
  font-weight: 600 !important;
}

/*changePassword */
.changePasswordOuter{
    height: 100vh;
    justify-content: space-between;
}
.ChangePW input::placeholder {
    color:  rgba(233, 165, 243,40%) !important;
}
/*notification*/
.notifyOuter {
  max-height: 650px;
  height: 100%;
  overflow: auto;
  /* position: absolute; */
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0px 10px;
}
.ClearBtn{
  right: 60px !important;
}
/* width */
.notifyOuter::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.notifyOuter::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.notifyOuter::-webkit-scrollbar-thumb {
  background:  #8A7C8D; 
  border-radius: 10px;
}

/* Handle on hover */
.notifyOuter::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.notifyBoxunred {
  background: #bdadbe;
  box-shadow: 0px 4px 25px 0px #E9A5F326;
  border-radius: 20px;
  margin-bottom: 15px;
}
.notifyBox{
  background: white;
  box-shadow: 0px 4px 25px 0px #E9A5F326;
  border-radius: 20px;
  margin-bottom: 15px;
}
.notifyImage_ {
  width: 99px;
  height: 99px;
  border-radius: 50px !important;
}
.notifyInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}
.buttonOuter {
    text-align: end;
}
.ClearBtn {
  background-color: #fff;
  color: #E9A5F3;
}
.StreamNow {
  font-size: 10px;
  padding: 13px 15px;
}
.streamCircle {
  padding: 7px 13px;
}
.streamCircle img {
  max-width: 10px;
}
.notifyContent {
  width: calc(100% - 300px);
}
.notifyImg {
  max-width: 85px;
  margin-right: 25px;
}
.notifyImg img {
  border-radius: 100%;
}
.notifyUserImg {
  height: 47px;
  width: 47px;
  position: absolute;
  right: -10px;
  bottom: -15px;
  border: 2px solid #fff;
  border-radius: 50%;
}
.ChangePW .form-control::placeholder{
  color: #E9A5F3;
}
/*plan & subscriptiton*/
.planBoxOuter {
  box-shadow: 10px 15px 40px 0px #E9A5F31A;
  background-color: #fff;
  border-radius: 30px;
  max-width: 675px;
  width: 100%;
}
.planBoxInner {
  padding: 30px 30px 40px;
  display: flex;
  justify-content: space-between;
}
.textBg {
  padding: 5px 18px;
  background: rgb(233 165 243 / 9%);
  border-radius: 10px;
}
.autoRenew {
  width: 31px;
  height: 31px;
  background: #E9A5F3;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*payment method style start*/
.addNewCard{
  background-color: transparent;
  border: 1px solid #E9A5F3;
  color: #E9A5F3;
}
.cardOuter{
  background-image: url("../images/cardbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  
}
.cardOuter.active {
  box-shadow: 4px 1px 15px grey;
  border: 2px solid green;
}
.cards-box{
  height: 420px;
  overflow-y: auto;
}
/* width */
.cards-box::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.cards-box::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.cards-box::-webkit-scrollbar-thumb {
  background: #E9A5F3; 
}

.cardInner{
  padding: 30px 20px;
}
/* AddCard style start*/
.boldText{
  color: #2d2832 !important;
  font-weight: 500 !important;
}
.lightText{
  color: #8a7c8d !important;
  font-weight: 400 !important;
}

