/* Start Common Css */
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

button {
  cursor: pointer;
}

ul li {
  list-style: none;
  display: inline-block;
}

.heading {
  font-size: 34px;
  font-weight: 600;
  color: #2d2832;
}

.font-26 {
  font-size: 26px;
  font-weight: 600;
}

.font-28 {
  font-size: 28px;
  color: #2d2832;
  font-weight: 600;
}

.bigHeading {
  font-size: 52px;
  font-weight: 600;
  color: #2d2832;
  line-height: 56px;
}

.DefaultPara {
  font-size: 16px !important;
  line-height: 35px !important;
}

.extraSmallText {
  font-size: 8px;
  color: #8a7c8d;
}

.font-10 {
  color: #b9b0bb;
  font-size: 10px !important;
}

.subheading {
  font-size: 42px;
  color: #2d2832;
  font-weight: 600;
  line-height: 56px;
}

.bigText {
  color: #2d2832;
  font-size: 15px;
  font-weight: 500;
  width: 80% !important;
}

.bigText.home {
  width: 68%;
}

.swiperClass {
  /* width: 85%;
  position: static !important; */
  width: 77%;
  position: static !important;
  margin-right: 136px;
}

.swiperClass .swiper-button-prev {
  width: 52px;
  height: 52px;
  position: absolute;
  left: 16px;
  top: 52%;
}

.swiperClass .swiper-button-next {
  width: 52px;
  height: 52px;
  position: absolute;
  right: 16px;
  top: 52%;
  border: none !important;
}

.text {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 21px;
  color: #8a7c8d;
}

.inputOut {
  flex-wrap: wrap;
}

.subtext {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #8a7c8d;
  line-height: 22px;
  font-family: "Open Sans", sans-serif;
}

.medText {
  font-size: 22px;
  font-weight: 600;
  color: #2d2832 !important;
}

.dislike_ {
  width: 100%;
  max-width: 90px !important;
}

.colorPink {
  color: #e9a5f3 !important;
  border: none;
}

.cancelSubsText {
  color: #e9a5f3 !important;
  border: none;
  background-color: #fcf3fd;
}

.fontBold {
  font-weight: 600 !important;
}

.colorWhite {
  color: #fff !important;
}

.dflex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.item-center {
  align-items: center;
}

/* End Common Css */

/*navbar style start*/
.navbar {
  padding: 15px 0px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

button.navbar-toggler:focus {
  box-shadow: none;
}

span.navbar-toggler-icon i {
  color: #eda6ef;
}

.navbar-nav li {
  padding: 0px 28px;
}

.navbar-nav li a {
  color: #8a7c8d;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
}

.navbar-nav li a:hover {
  color: #e9a5f3 !important;
}

.navbar-nav li a:focus {
  color: #e9a5f3 !important;
}

.navbar-nav li a.nav-link.active {
  color: #e9a5f3;
}

.header-btn {
  padding: 15px 31px;
  background: #ffffff;
  box-shadow: 0px 15px 25px rgba(233, 165, 243, 0.15);
  border-radius: 50px;
  border: none;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 10px;
}

.profileBtnOuter {
  visibility: visible;
  padding: 0px !important;
  position: relative;
  left: 52px;
}

li.p-0.buttonOuter {
  display: flex;
}

.emailText {
  line-height: 1.2em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-width: 185px;
}

.dropdownInner {
  border-bottom: 1px dashed #e9a5f3;
  padding-bottom: 12px;
}

.ProfileDropdown {
  padding: 15px 20px;
  box-shadow: 0px 12px 30px 0px #e9a5f34d;
  border: none;
  border-radius: 20px;
  width: 280px;
  margin-top: 15px !important;
  transform: translate(-145px, 10px);
}

.notificationBox {
  padding: 0px 10px;
  border-radius: 50px;
  background: #eaa7ef;
  font-size: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProfileDropdown li {
  padding: 0px;
  display: flex;
  padding: 10px 0px;
}

.ProfileDropdown li a {
  padding: 0px;
  margin-left: 15px;
}

.ProfileDropdown li a:hover {
  background-color: #fff;
}

.profileBtn {
  box-shadow: 0px 15px 25px 0px #e9a5f326;
  background-color: #fff !important;
  border-radius: 50px;
  padding: 2px 20px 2px 0px;
}

.userImgNav {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 11px;
  object-fit: cover;
}

.navbar-nav .desktopLive {
  padding: 0 !important;
}

.favouriteIcon {
  width: 55px;
  height: 55px;
  background: #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 27px;
  position: relative;
  cursor: pointer;
}

.countOuter {
  width: 19px;
  height: 19px;
  background-color: #e9a5f3;
  border-radius: 100%;
  position: absolute;
  top: -5px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.countOuter span {
  color: #fff;
  font-size: 12px;
}

.signUp {
  color: #e9a5f3;
}

.login {
  background: #e9a5f3;
  color: #fff;
}

.banner-section .swiper-button-next {
  top: 95%;
  right: 0;
}

.banner-section .swiper-button-prev {
  top: 95%;
  left: 90%;
  transform: translate(-95%, 0px);
}

.banner-section .swiper-button-next:after {
  display: none;
}

.banner-section .swiper-button-prev:after {
  display: none;
}

.nextArrow {
  padding: 8px 14px;
  background: #fff;
  border-radius: 100%;
  border: 1px solid #e9a5f3;
}

.prevArrow {
  padding: 9px 18px;
  background: #f1d7f1;
  border-radius: 100%;
}

/*navbar style end*/

/*banner section style start*/
.banner-section {
  padding: 200px 0px 0px;
  position: relative;
  background-image: url(../images/banner-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}

.banner-section:before {
  content: "";
  position: absolute;
  top: 0;
  background-image: url(../images/shape.png);
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 840px;
  height: 800px;
  width: 100%;
  right: 0;
  z-index: -1;
}

.contentSection {
  padding: 100px 0px;
}

.BannerContent {
  color: #8a7c8d;
  font-size: 14px;
}

.BannerContent>div {
  padding: 0 17px;
}

.BannerContent>div p {
  text-align: justify;
}

.ourCustomerHead {
  color: #2d2832;
  font-size: 42px;
  font-weight: 600;
}

.banner-content {
  max-width: 300px;
}

.rightShape {
  max-width: 585px;
  position: relative;
  bottom: 13px;
  right: 20px;
}

.button {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #ffffff;
  box-shadow: 0px 15px 25px rgba(233, 165, 243, 0.15);
  border-radius: 50px;
  padding: 9px 30px;
  color: #fff;
  margin-bottom: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.rectangleImg {
  background-image: url(../images/rectangle.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 516px;
  min-height: 156px;
  margin-top: 60px;
  border-radius: 20px;
}

.youtubeIcon {
  margin-right: 8px;
}

.btn-outer {
  padding: 30px 25px 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: end;
}

/*our recipe style start*/
.ourRecipe {
  padding: 120px 0px;
  position: relative;
}

.RecipesPage {
  padding: 50px 0px;
  margin-top: 9%;
}

.ourRecipe:before {
  position: absolute;
  content: "";
  right: 150px;
  bottom: 0px;
  width: 80px;
  height: 80px;
  background-image: url(../images/leaf.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.RecipesPage::before {
  content: none;
}

.searchInput input {
  background: #ffffff;
  border-radius: 50px;
  padding: 14px 30px;
  position: relative;
  filter: drop-shadow(0px -1px 34px rgba(233, 165, 243, 0.25));
  border: none;
}

.searchInput input:focus {
  box-shadow: none;
}

.searchInput {
  display: flex;
  align-items: center;
}

.searchCirle {
  padding: 13px 14px !important;
  position: relative;
  right: 3px;
  border: 2px solid #fff !important;
  box-shadow: 4px 6px 17px #dddddda6;
}

.tabIcons {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fcf3fd;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding: 10px;
}

.tabIcons img {
  max-width: 30px;
}

.tab span {
  padding-left: 5px;
}

.whiteRec img {
  position: relative;
  top: 10px;
  right: 0px;
}

.star {
  padding-right: 5px;
}

.tabnav {
  padding: 0;
}

.tabnav li {
  max-width: 190px;
  width: 100%;
}

.tab {
  background: #ffffff;
  border: 1px solid rgba(189, 182, 190, 0.15);
  border-radius: 50px;
  padding: 11px 40px 11px 5px;
  max-width: 190px;
  width: 100%;
  margin-bottom: 9px;
  display: flex;
  align-items: center;
  height: 50px;
}

.tab.activeTab {
  background: #e9a5f3;
  color: #fff;
}

.tab.activeTab .tabIcons {
  background-color: #fff;
}

.breakfastImg {
  height: 100%;
}

.verticalimg {
  display: none;
}

.foodBoxOuter {
  background: #fcf3fd;
  border-radius: 22px;
  height: 100%;
  margin-left: 40px;
  position: relative;
}

.verticalRecipeImg img {
  height: 100vh;
  max-height: 635px;
}

.RecipesPage .foodBoxOuter {
  height: 100%;
  width: 100%;
}

.foodInner {
  padding: 20px 20px 20px 0px;
  display: flex;
  align-items: center;
}

.filterBottomBorder {
  border-bottom: 1px solid rgba(233, 165, 243, 20%);
  padding-bottom: 15px;
  margin-bottom: 0;
  position: relative;
}

.filterBottomBorder:before {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  width: 20px;
  height: 2px;
  background: #e9a5f3;
}

.ratingBox {
  padding: 2px 10px;
  border-radius: 20px;
  background: #fff;
  display: flex;
  align-items: center;
  position: relative;
  left: 20px;
  top: 15px;
  max-width: 56px;
}

/* .foodboxInner {
  padding: 20px 10px 20px 20px;
  position: relative;
  right: 25px;
} */
.boxImg {
  max-width: 95px;
  position: relative;
  right: 35px;
}

.boxImg img {
  max-width: 100px;
}

.cookingOuter {
  border-right: 1px solid #e9a5f3;
  padding-right: 35px;
}

.seeFullrecipe {
  color: #e9a5f3;
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #ffffff;
  border-radius: 450px;
  padding: 6px 17px;
  font-size: 13px;
}

.heartCircle {
  padding: 6px 10px;
  background-color: #fff;
  border-radius: 100%;
  margin-left: 10px;
  cursor: pointer;
}

.paraGraph {
  max-width: 200px;
}

/*tradingRecipe section style start */
.tradingRecipe {
  padding: 80px 0px;
  background-image: url(../images/bg-shape.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 900px;
  position: relative;
}

.tradingRecipe:before {
  position: absolute;
  content: "";
  top: -135px;
  right: 0;
  background-image: url(../images/foodplate2.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 250px;
  height: 270px;
  z-index: 1;
}

.Slide {
  position: relative;
  display: flex;
  justify-content: end;
}

.RotateArrow img {
  transform: rotate(221deg);
  width: 17px;
  height: auto;
}

.Rotatearrow {
  transform: rotate(45deg);
  position: relative;
  top: 0px;
  left: 0px;
}

/* .Slide.One:before {
  width: 100%;
} */
.Slide:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 100%;
  background-image: url(../images/overlay.png);
  background-repeat: no-repeat;
  background-size: 100% 55%;
  z-index: 1;
  width: 215px;
  border-radius: 35px;
  background-position: center bottom;
}

.ratingStar {
  background: #fff;
  padding: 2px 6px;
  border-radius: 50px;
}

.imgContent {
  padding: 0px 20px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: none;
  width: 100%;
  max-width: 260px !important;
}

.BottomHeading {
  padding: 0px 20px;
  text-align: center;
  position: absolute;
  bottom: 0;
  z-index: 1;
  max-width: 177px;
  margin: 0 auto;
  line-height: 1.5;
}

/* .slideOnebtn {
  left: 60% !important;
} */

.downarrow {
  width: 62px;
  height: 62px;
  background-color: #e9a5f3;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  border: 5px solid #fff;
  cursor: pointer;
}

.topHeading {
  position: relative;
}

.topHeading:before {
  position: absolute;
  content: "";
  left: 14vw;
  top: -60px;
  width: 80px;
  height: 80px;
  background-image: url(../images/leaf.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.sliderOuter {
  padding-top: 54px;
}

.tradingRecipe .swiper-button-next {
  top: 95%;
  right: 0px;
}

.tradingRecipe .swiper-button-prev {
  top: 95%;
  left: 90%;
}

.tradingRecipe .swiper-button-next:after {
  display: none;
}

.tradingRecipe .swiper-button-prev:after {
  display: none;
}

.slideOnebtn {
  display: none;
}

.tradingRecipe .swiper-slide.swiper-slide-active .slideOnebtn {
  display: block;
}

.tradingRecipe .swiper-slide.swiper-slide-active .sliderImg {
  max-width: 260px !important;
}

.tradingRecipe .swiper-slide.swiper-slide-active .sliderImg img {
  width: 260px !important;
}

.tradingRecipe .swiper-slide.swiper-slide-active .Slide:before {
  width: 260px !important;
}

.swiper-slide.swiper-slide-active .BottomHeading {
  display: none;
}

.swiper-slide.swiper-slide-active .imgContent {
  display: block;
}

.sliderImg {
  max-width: 215px !important;
  height: 510px;
}

.sliderImg img {
  height: 510px;
  border-radius: 35px;
  object-fit: cover;
}

.tradingRecipe .swiper-wrapper {
  padding-bottom: 80px;
}

/*streamsLive section style start*/
.streamsLive {
  padding: 50px 0px;
  background-image: url(../images/livestream-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 700px;
}

.streamsInner {
  position: relative;
}

.chefBtn {
  position: absolute;
  top: 36px;
  left: 36px;
  max-width: 125px;
  width: 100%;
  padding: 9px 10px;
  font-size: 10px;
}

.verticalImg {
  position: absolute;
  right: 0;
  top: 150px;
}

.chefBtn2 {
  top: 84%;
  left: 75px;
}

.iconBox {
  position: absolute;
  bottom: -75px;
  left: 30%;
  transform: translateX(-30%);
  width: 130px;
  height: 130px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 10px 50px 0px #e9a5f340;
}

.streamBox {
  margin: 65px 0px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.streamContent {
  max-width: 520px;
  margin-left: auto;
}

.redDot {
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: red;
  border-radius: 50%;
  margin-right: 10px;
}

.mszBox {
  position: absolute;
  z-index: 2;
  max-width: 75px;
  bottom: 25px;
  right: 0;
}

.muteBtn {
  background-color: grey;
}

.Buttons {
  position: absolute;
  bottom: 25px;
  left: 45%;
}

.endcallouter {
  left: 45%;
  bottom: 65px;
}

.muteIcon {
  height: 22px;
  margin-right: 5px;
}

.liveText {
  position: absolute;
  bottom: 0;
  z-index: 5;
  left: 20px;
}

.liveText p {
  color: #dc3545;
  font-weight: 700;
  font-size: 16px;
}

/*ourCustomers style start*/
.ourCustomers {
  margin: 250px 0px 0px;
  padding: 200px 0px 50px;
  background-image: url(../images/customerbg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}

.ourCustomers:before {
  background-image: url(../images/foodplate3.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 430px;
  height: 430px;
  content: "";
  position: absolute;
  top: -210px;
  left: 50%;
  transform: translateX(-50%);
}

.ourCustomers:after {
  background-image: url(../images/border-circle.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 780px;
  height: 450px;
  content: "";
  position: absolute;
  top: -260px;
  left: 51%;
  transform: translateX(-50%);
  z-index: -1;
}

.ourCustomers .swiper-button-next {
  top: 95%;
  right: 50%;
  transform: translate(95%, 0px);
}

.ourCustomers .swiper-button-prev {
  top: 95%;
  left: 48%;
  transform: translate(-95%, 0px);
}

.ourCustomers .swiper-button-next:after {
  display: none;
}

.ourCustomers .swiper-button-prev:after {
  display: none;
}

/* .ourCustomers .swiper-slide.swiper-slide-next{
  transform: scale(1.1);
} */
.ourCustomers .swiper-wrapper {
  padding-bottom: 120px;
  /* display: flex;
  justify-content: center; */
}

.shapeBg {
  background-image: url(../images/review-Bg.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 65px;
  width: 422px;
  margin: 0 auto;
}

.shapebgInner {
  padding: 20px 25px;
}

.starOuter li {
  margin-right: 10px;
}

.starOuter li:last-child {
  margin-right: 0;
}

.starOuter {
  padding: 0;
  text-align: center;
}

.userImg {
  text-align: center;
  position: relative;
  bottom: 20px;
}

/*whyChooseus section style*/
.whyChooseus {
  padding: 50px 0px 0px;
}

.whyChooseContent {
  max-width: 350px;
}

.downloadApps-bg {
  background-size: 94% 100%;
  top: 198px;
  background-image: url(../images/downloadourbg.png);
  background-repeat: no-repeat;
  padding: 65px 48px 80px 48px;
  max-height: 437px;
  height: 100%;
  position: relative;
}

.downloadApps {
  background-image: url(../images/downloadourbg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 65px 48px 80px 48px;
  max-height: 437px;
  height: 100%;
  position: relative;
  top: 180px;
}

.downloadAppsOuter {
  background-image: url(../images/download-app-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 1173px;
  width: 100%;
  position: relative;
  padding: 100px 0px 0px;
  background-position: center top;
}

.appBtn {
  padding: 16px 35px;
  background-color: #2d2832;
  color: #fff;
  border-radius: 50px;
  border: none;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.appIcon {
  padding-right: 16px;
}

.twoMobileImg {
  position: relative;
  bottom: 30%;
}

/*footer section*/
.footer-section {
  background-color: #25202c;
}

.topFooter {
  background-color: #2d2832;
  padding: 50px 0px;
}

.footerInput input {
  background-color: #201c25;
  padding: 14px 30px;
  border-radius: 50px;
  border: none;
  color: rgb(138 124 141 / 70%);
  font-size: 12px;
}

.footerInput input:focus-visible {
  outline: none;
}

.footerListOuter {
  display: flex;
}

.footerList {
  width: 25%;
  background-color: #352f3e;
  /* padding: 35px 25px 35px 80px; */
}

.footerLogo {
  text-align: center;
  padding: 25px;
}

.readMore {
  margin: 0 auto;
  color: #e9a5f3;
  background-color: rgb(233 165 243 / 20%);
  border: 1px solid #e9a5f3;
  padding: 10px 25px;
  font-size: 13px;
  font-weight: 500;
}

.footerListRight {
  width: 75%;
  padding: 0px;
}

.footerLink {
  width: 20%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.footerListInner {
  padding: 30px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footerLink li a {
  line-height: 32px;
  color: #8a7c8d;
  font-size: 14px;
}

.footerIcon {
  margin-right: 10px;
  margin-top: 8px;
}

.iconOuter {
  display: flex;
  align-items: flex-start;
}

.backTotop {
  padding: 9px 15px;
  background-color: #201c25;
  border: none;
  border-radius: 50px;
  color: #675f73;
  font-size: 10px;
}

.cicleBlack {
  padding: 6px 12px !important;
  background-color: #352f3e !important;
  position: relative;
  right: 4px;
}

.bottomBtn {
  transform: rotateZ(270deg);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 75px;
}

.copyrightFooter {
  padding: 10px 0px 10px;
  border-top: 1px solid #352f3e;
}

.footercircle {
  padding: 13px !important;
  position: relative;
  right: 4px;
}

.centerImg {
  position: relative;
  right: 4px;
}

/*---Eye Icon*---*/
.show-icon {
  background-image: url("../images/hide.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  z-index: 4;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 19px;
  cursor: pointer;
}

.hide-icon.hide-icon {
  background-image: url("../images/show.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  z-index: 4;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 19px;
  cursor: pointer;
}

/*---Eye Icon End*---*/

.slick-track {
  display: flex;
}

/*Recipe page style start*/
/*recipeBanner style start*/

.recipeBanner {
  background-image: url(../images/homebg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  position: relative;
  z-index: 0;
  padding: 100px 0px;
  height: 655px;
  background-position: 100% 100%;
}

.tabsSection {
  padding: 50px 0px;
}

.star {
  max-width: 20px;
}

.pararecipe {
  line-height: 1.2em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.fullrecipeImg img {
  width: 95px !important;
  height: 95px;
  border-radius: 8px;
  object-fit: cover;
}

.fullrecipeImg {
  width: 95px;
}

.foodBox {
  padding-left: 20px;
}

/*favorite page style*/
.favoritesBanner {
  background-image: url(../images/favoritebg.png);
  height: 480px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding: 100px 0px;
  z-index: 0;
}

.favclassBanner {
  height: 375px;
}

.contactBanner {
  height: 385px;
}

.termsBanner {
  height: 515px !important;
}

.thanksBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.RecipeTabs {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.RecipeTabs .nav {
  justify-content: space-between;
  /* position: absolute; */
  /* bottom: -30px; */
  /* left: 0; */
  /* right: 0; */
  background: #fff;
  /* max-width: 240px; */
  margin: 0 auto;
  border-radius: 30px;
  /* align-items: center; */
  box-shadow: 0px 4px 35px 0px #e9a5f340;
  padding: 5px;
}

.favclassBanner .RecipeTabs .nav {
  display: inline-block;
}

.RecipeTabs .nav li {
  margin-bottom: 0 !important;
}

.RecipeTabs .nav li:last-child button {
  margin-right: 0;
  margin-left: 10px;
}

.FavRecipeTabs li.active {
  background: #e9a5f3;
  box-shadow: 0px 15px 25px 0px #e9a5f326;
  margin-bottom: 0;
}

.FavRecipeTabs li button.active {
  background: #e9a5f3;
  box-shadow: 0px 15px 25px 0px #e9a5f326;
  margin-bottom: 0;
}

.RecipeTabs .IngredientsList li button.active {
  background: #e9a5f3;
  box-shadow: 0 15px 25px 0 #e9a5f326;
  margin-bottom: 0;
  border: none;
}

.RecipeTabs .IngredientsList li button {
  background: transparent;
  box-shadow: none;
  margin-right: 0;
  margin-bottom: 0;
}

.IngredientsList li button {
  padding: 10px 25px;
  border-radius: 50px;
  background-color: #fff;
  font-weight: 500;
  margin-right: 10px;
  box-shadow: 0px 5px 20px 0px #e9a5f326;
  margin-bottom: 10px;
  border: 1px solid rgb(233 165 243 / 30%);
}

.RecipeTabs .IngredientsList li.active {
  background: #e9a5f3;
  box-shadow: 0px 15px 25px 0px #e9a5f326;
  margin-bottom: 0;
}

.RecipeTabs .IngredientsList li.active a {
  color: #fff;
}

.RecipeTabs .IngredientsList li {
  background: transparent;
  box-shadow: none;
  margin-right: 0;
  margin-bottom: 0;
}

.RecipeTabs .IngredientsList li button {
  background: transparent;
  box-shadow: none;
  margin-right: 0;
  margin-bottom: 0;
}

.classBox {
  border-radius: 22px;
  background-color: #fcf3fd;
  max-width: 365px;
  max-height: 535px;
  height: 100%;
}

.classVedioImg {
  margin: 0 auto 21px;
  position: relative;
}

.classVedioImg img {
  width: 335px !important;
  height: 271px;
  border-radius: 10px;
  object-fit: cover;
}

.classVedioImg:before {
  background-image: url(../images/vedioicon.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  margin: 0 auto;
}

.classBoxInner {
  padding: 15px 15px 25px 15px;
}

.classtextbox {
  min-width: 45px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 2px 12px;
}

.instructionUser {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 2px solid #fcf3fd;
}

.instructionUserOuter {
  transition: all 0.5s ease-in-out;
}

.instructionUserOuter:hover .instructorName {
  display: block;
  position: absolute;
  top: 40px;
  right: 0;
}

.instructorName {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #e9a5f3;
  color: #fff;
  display: none;
  font-weight: 500;
  position: relative;
}

.instructorName::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #e9a5f3;
  left: 10px;
  top: -5px;
  border-radius: 3px;
  transform: rotate(45deg);
}

.instructionUser:nth-child(2) {
  position: relative;
  right: 8px;
  z-index: 1;
}

.instructionUser:nth-child(3) {
  position: relative;
  right: 14px;
  z-index: 1;
}

.instructionUser:nth-child(4) {
  position: relative;
  right: 20px;
  z-index: 1;
}

/*subscription page style start */
/* .subscriptionBanner {
  height: 100%;
  min-height: 400px;
} */
.SubscriptionBox {
  box-shadow: 10px 15px 40px 0px #e9a5f31a;
  background: #fff;
  border-radius: 30px;
  min-height: 470px;
  height: 100%;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.SubscriptionBox:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 50%;
  background-image: url("../images/bglines.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  z-index: 0;
}

.SubscriptionBox:hover::before {
  background-image: url("../images/bglineswhite.svg");
}

.SubscriptionBox:hover .mostPopularImg {
  display: block;
}

.mostPopularImg {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  transition: all 0.3s ease-in-out;
}

.SubscriptionBoxInner {
  padding: 50px 30px 40px;
  transition: all 0.3s ease-in-out;
}

.subscriptionButton {
  position: relative;
  z-index: 1;
}

.SubscriptionBoxInner .substext {
  background-color: rgba(252, 243, 253, 0.7);
  padding: 5px 18px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 160px;
  margin: 0 auto;
}

.subscriptionOuter {
  margin-top: -150px;
  position: relative;
  z-index: 0;
  padding-bottom: 100px;
}

.SubscriptionBox:hover {
  background: #e9a5f3;
  box-shadow: 10px 15px 40px 0px #e9a5f31a;
}

.SubscriptionBoxActive {
  background: #e9a5f3;
  box-shadow: 10px 15px 40px 0px #e9a5f31a;
}

.SubscriptionBox .textActive {
  color: #fff !important;
}

.SubscriptionBoxActive .SubscriptionBoxInner h2 {
  color: #fff;
}

.SubscriptionBoxActive .SubscriptionBoxInner p {
  color: #fff;
}

.SubscriptionBoxActive .substext {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.SubscriptionBoxActive .darktick {
  display: none;
}

.SubscriptionBoxActive .tickwhite {
  display: block;
  max-width: 20px;
  position: relative;
  top: 11px;
}

.SubscriptionBoxActive:before {
  background-image: url("../images/bglineswhite.svg");
  opacity: 0.4;
}

.SubscriptionBox:hover .SubscriptionBoxInner p {
  color: #fff;
}

.SubscriptionBox:hover .SubscriptionBoxInner h2 {
  color: #fff;
}

.SubscriptionBox:hover .SubscriptionBoxInner h2 {
  color: #fff;
}

.SubscriptionBox:hover .substext {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
}

.SubscriptionBox:hover .login-btn {
  background-color: #fff;
  color: #2d2832;
}

.SubscriptionBox:hover .circle-btn {
  background-color: #fff;
  padding: 14px;
}

.SubscriptionBox:hover .whiterectabgle {
  display: block;
}

.SubscriptionBox:hover .darkrechtangle {
  display: none;
}

.whiterectabgle {
  display: none;
}

.darkarrow {
  display: none;
}

.SubscriptionBox:hover .darkarrow {
  display: block;
}

.SubscriptionBox:hover .whitearrow {
  display: none;
}

.tickwhite {
  display: none;
}

.SubscriptionBox .ScrollText {
  padding: 10px 0px;
  height: 160px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.SubscriptionBox:hover .ScrollText {
  overflow-y: auto;
}

/* width */
.ScrollText::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.ScrollText::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.ScrollText::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.ScrollText::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.SubscriptionBox:hover .tickwhite {
  display: block;
  max-width: 20px;
  position: relative;
  top: 11px;
}

.SubscriptionBox:hover .darktick {
  display: none;
}

/* Contact Csss Start */
.contactus_ {
  font-family: Poppins;
  font-size: 52px;
  font-weight: 600;
  text-align: center;
  color: #2d2832;
}

.contactus_Txt {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #8a7c8d;
  margin-top: 30px;
}

.Contactus_class {
  padding-top: 70px;
}

.contactus_getintouch li a {
  display: flex;
  align-items: center;
}

/* Section Css Start */
.contactUs_section {
  padding-bottom: 100px;
  position: relative;
  z-index: 0;
  margin-top: -75px;
}

.send_message {
  position: relative;
}

/* Left Box Css Start */
.left_boxtxt {
  color: #ffffff;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 400;
}

.touch_class {
  display: flex;
  align-items: center;
  padding: 5px 32px;
}

.contactus_leftTouchin {
  width: 100%;
  min-width: 358px;
  position: relative;
  z-index: 1;
}

.contactUs_section .row {
  position: relative;
  z-index: 1;
}

.top {
  border-radius: 30px;
  background: #e9a5f3;
  display: flex;
  flex-direction: column;
  max-width: 358px;
  width: 100%;
  height: 229px;
  padding: 30px 16px;
  margin-bottom: 20px;
}

.getin_Touch {
  color: #fff;
  font-size: 22px;
  font-family: Poppins;
  font-weight: 600;
  margin-left: 30px;
}

.left_icon {
  padding: 7px 11px 7px 0;
}

/* Left Box Css End */

/* right div Css Start */
.right_div {
  box-shadow: rgba(233, 165, 243, 0.1) 10px 15px 40px 0px;
  border-radius: 30px;
  background: #fff;
  position: relative;
}

.contactus_form {
  padding: 75px 30px 75px 100px;
}

.login_create {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  align-items: end;
  margin: 0;
}

.Login_button {
  margin-top: 49px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-direction: row;
}

.forgotlogin_ {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  background: #e9a5f3;
  border-radius: 50px;
  border: none;
  padding: 17px 31px;
}

.forgotlogin_::after {
  content: "";
  position: absolute;
  left: 128px;
  top: 13px;
  height: 21px;
  width: 16px;
  background-image: url("../images/phone.svg");
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
}

.forgotcircle {
  width: 54px;
  height: 56px;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 104px;
  background-color: #e9a5f3;
  background-image: url("../images/phone.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.form-heading {
  color: #2d2832;
  font-size: 34px;
  font-family: Poppins;
  font-weight: 600;
}

.contactUsforminput_field {
  border-radius: 50px;
  background: rgba(252, 243, 253, 0.7);
  border: none;
  max-width: 100%;
  padding: 17px 30px;
  width: 100%;
  /* margin-bottom: 20px; */
}

.contactUsforminput_field:focus-visible {
  outline: none;
}

.contactusTextarea {
  border-radius: 20px;
  resize: initial;
  height: 120px !important;
}

.contactusTextarea::-webkit-scrollbar {
  display: none;
}

.form_section {
  position: absolute;
  top: -48px;
  border-radius: 30px;
  /* height: 100%; */
  max-width: 890px;
  height: 579px;
  left: 373px;
}

.form-div {
  display: flex;
  flex-direction: column;
  width: 968px;
  height: 579px;
  padding: 5% 20%;
  justify-content: space-between;
}

.left-side {
  position: absolute;
  z-index: 1;
}

.inputfield_parent {
  display: flex;
}

/* Contact us page end  */
/*classes page style start*/
.ClassRecipeTabs .nav {
  max-width: 430px;
  position: static;
}

.classtabnav {
  display: flex;
  justify-content: center;
}

.classtabnav .tab.activeTab {
  max-width: 190px;
  width: 100%;
}

.classtabnav .tab {
  max-width: 190px;
  width: 100%;
}

.filterCategory {
  padding-bottom: 60px;
  position: relative;
  z-index: 0;
}

.filterCategory .swiper-slide {
  max-width: 140px !important;
  margin-right: 0;
}

.classdetailContent .DefaultPara {
  font-size: 12px;
}

.filterCategory .swiper-slide-active {
  max-width: 140px !important;
  margin-right: 0;
}

.filterCategory .swiper-button-next:after {
  display: none;
}

.filterCategory .swiper-button-prev:after {
  display: none;
}

.classesSlider .swiper-slide.swiper-slide-next {
  transform: scale(1.5);
  position: relative;
  z-index: 1;
}

.classesSlider .swiper-button-next:after {
  display: none;
}

.classesSlider .swiper-button-prev:after {
  display: none;
}

.classesSlider .sliderimg {
  margin-top: 28px;
}

.classtabsOuter {
  padding: 220px 0px;
}

.sliderBoxOuter {
  position: relative;
  display: flex;
  justify-content: center;
  width: 700px;
  margin: 0 auto;
  /* top: 20px; */
}

.sliderImgOuter:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
}

.sliderBoxdetail {
  top: -120px;
}

.joinBtn {
  padding: 7px 13px !important;
}

.joinBtn img {
  width: 10px;
}

/* .sliderBoxInner .button {
  color: #2d2832;
  border-color: #2d2832;
} */
.sliderImgOuter {
  border: 8px solid #fff;
  border-radius: 20px;
  position: relative;
}

.sliderImgOuter img {
  width: 700px;
  max-height: 370px;
  height: 100%;
  border-radius: 20px;
}

.sliderBoxInner {
  padding: 34px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.tabnav.classtabnav li {
  margin-right: 10px;
}

/*about us page style*/
.founder {
  background-image: url("../images/bg-shape.png");
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 100%;
  padding: 0px 0px 100px;
  position: relative;
}

.healthyfood_Images {
  margin-top: -100px;
  position: relative;
}

.ownerdetail_Lynnette:before {
  content: "";
  position: absolute;
  top: -65px;
  right: -38px;
  background-image: url("../images/foodplate3.png");
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 251px;
  height: 244px;
}

.signText {
  font-size: 74px;
  font-family: "Qwitcher Grypen", cursive;
  font-weight: 400;
}

.ownerdetail_Lynnette {
  padding: 148px 29px 0px 51px;
}

.founderimg_Lynnette {
  margin: 61px 71px 12px 12px;
  float: right;
}

.ownerdetail_Lynnette {
  position: relative;
}

.ownerdetail_Lynnette:after {
  content: "";
  position: absolute;
  top: -28px;
  right: 241px;
  background-image: url("../images/leaf.png");
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 62px;
  height: 62px;
  width: 100%;
}

.founderimg_lynnette:before {
  content: "";
  position: absolute;
  top: 6px;
  right: 56%;
  background-image: url("../images/leaf.png");
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 114px;
  height: 105px;
}

/*classdetailpage style start*/
.ingre_cards {
  background-image: url("../images//aboutusIngredients.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  border-radius: 15px;
  min-height: 300px;
}

.Nutrition_cards {
  background-image: url("../images/aboutusNutrition.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  border-radius: 15px;
  min-height: 300px;
}

.cards_style {
  padding: 20px 0px 20px 21px;
  display: flex;
  flex-direction: column;
}

.ingredient_heading {
  border-bottom: 1px dashed rgba(233, 165, 243, 1);
}

.perfectbreakfast {
  position: relative;
}

.aboutus_solidborder {
  border-bottom: 1px solid #e9a5f3;
}

.aboutus_dashedborder {
  border-bottom: 1px dashed #e9a5f3;
  margin-bottom: 30px;
}

.cards_style li {
  line-height: 40px;
}

.Aboutus_cook_txt {
  display: flex;
  max-width: 136px;
  width: 100%;
  flex-direction: column;
  padding: 22px 0px 8px 15px;
}

.Aboutus_cookingtimmimg {
  color: #8a7c8d;
}

.Aboutusrecommendrecipe {
  border-radius: 22px;
  background: #fcf3fd;
  display: flex;
  padding: 54px 40px 54px 40px;
  margin-top: 40px;
}

.likeDislike {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
}

.likeDislike a {
  display: flex;
  flex-direction: column;
}

.RecipesStepOuter {
  max-width: 575px;
}

.RecipesStepOuter:before {
  left: 83px !important;
  height: 100% !important;
}

.reply_button {
  background-color: rgb(253 236 255);
  border: none;
  border-radius: 20px;
  padding: 6px 18px;
}

.Star_rating {
  width: 15px;
}

.starRating li {
  margin-right: 5px;
}

.commentsright_Side {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #e9a5f3;
  padding-bottom: 35px;
}

.aboutus_Anna {
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
  padding-top: 30px;
}

.profile_outer {
  display: flex;
}

.profile_outer img {
  width: 58px;
  height: 58px;
  border-radius: 100%;
}

.ReportsOuter {
  max-width: 115px;
}

.replyComments {
  width: 150px;
}

.replyCommentText {
  margin-left: 70px;
  margin-top: 30px;
}

.starRating {
  padding: 0;
}

.aboutus_leena {
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
}

.anna_Report {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: end;
  margin-bottom: 20px;
}

.anna_Report p {
  margin-bottom: 0;
  margin-left: 3px;
}

.Readme_button {
  display: flex;
  justify-content: end;
}

.Aboutus_Ratetherecipe {
  display: flex;
  justify-content: space-between;
}

.Aboutus_reciperating_Star {
  width: 100%;
  max-width: 80px;
  height: 37px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 34px;
  background: #fcf3fd;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.recipeBottom {
  padding: 100px 0px 0px;
}

.ratinglike_Options {
  position: absolute;
  right: 25px;
  top: 23px;
}

.AboutUs_likeComments {
  border-radius: 47px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
}

.bc_mainimage {
  position: relative;
  margin-top: -100px;
  /* border-color: #251c1d; */
}

.Aboutus_foodimage {
  height: 100vh;
  object-fit: cover;
}

.Aboutus_foodimage {
  border-radius: 10px;
}

.noDataText {
  font-size: 20px !important;
  font-weight: 600;
  line-height: 21px;
  color: #8a7c8d;
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-top: 71px !important;
}

.noDataText-Fav {
  font-size: 15px !important;
  font-weight: 600;
  line-height: 21px;
  color: #8a7c8d;
  text-align: center;
}

.navBarText {
  font-size: 25px !important;
  font-weight: 590;
  line-height: 21px;
  color: #8a7c8d;
  text-align: center;
}

.noLiveText {
  font-size: 13px !important;
  font-weight: 600;
  line-height: 21px;
  color: #8a7c8d;
  text-align: center;
}

.RecipesSwiper {
  padding: 50px 0px 100px;
  margin-top: 80px;
  background-image: url(../images/recipesdetailbg.png);
  background-position: center;
  background-size: 100% 100%;
  position: relative;
}

.RecipesSwiper:before {
  content: none;
}

.RecipesSwiper:after {
  content: none;
}

.RecipesSwiper .swiper-button-prev {
  top: 92%;
  left: 48%;
}

.RecipesSwiper .swiper-button-next {
  top: 92%;
  right: 45%;
}

.recipedownarrow {
  top: 135px;
  z-index: 1;
  cursor: pointer;
}

.upcomingclassarrow {
  top: -79px;
}

.viewmore {
  border: none;
  background: transparent;
  position: relative;
  top: 50px;
  font-weight: 500;
}

.Aboutus_star {
  display: flex;
  justify-content: center;
}

.AboutUs_likecomment_show {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 13px 0px;
}

.AboutUs_Startrating {
  width: 100%;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  margin-right: 16px;
  margin-bottom: 16px;
  padding: 10px 5px;
}

.Aboutus_Star {
  max-width: 15px;
}

.Aboutus_heartimg {
  width: 77px;
  height: 77px;
  border-radius: 100%;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  position: relative;
}

.AboutUs_hearticon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fillHeart {
  max-width: 28px;
  width: 100%;
}

.larrow_1 {
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed #e9a5f3;
  padding-bottom: 31px;
}

/*classdetailpage style start*/
.InstructorOuter {
  position: relative;
  left: 5px;
}

.topText {
  border-bottom: 1px dashed #e9a5f3;
}

.solid_border {
  border-bottom: 1px solid #e9a5f3;
  margin-bottom: 9px;
}

.dashed_border {
  border-bottom: 1px dashed #e9a5f3;
  margin-bottom: 30px;
}

.classdetailtext {
  max-width: 100%;
}

.bookclassBtn {
  font-size: 11px !important;
}

.recommendOthers {
  display: flex;
  justify-content: space-between;
  border-radius: 22px;
  background: #fcf3fd;
  padding: 35px 40px;
  align-items: center;
  margin-top: 54px;
}

.innerlikedtxt {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.classdetailbg {
  position: relative;
  margin-top: -83px;
}

/* .classdetailbg:before {
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: url(../images/vedioicon.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 150px;
  height: 150px;
  content: "";
  transform: translate(-50%, -50%);
} */
.loaderImg_ {
  width: 100px;
}

.watchvediotext {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 100%;
  left: 50%;
  margin-top: 0px;
  text-align: center;
}

.textWatchVdeo {
  top: 60%;
}

.watchdetailpage {
  top: 60%;
}

@keyframes rotate-full {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animated {
  animation-duration: 10s;
  animation-fill-mode: both;
}

.animated .infinite {
  animation-iteration-count: 10s;
}

.rotate {
  animation: rotation-full 5s;
}

.rotate-full {
  animation: infinite-spinning 5s;
}

/*report Modal style start*/
.reportModalInner {
  padding: 10px 60px 30px 60px;
}

.uploadEvidence {
  padding: 35px;
  border: 1px dashed rgba(233, 165, 243, 0.5);
  text-align: center;
}

/*recipes pagination style*/
.pagination-next {
  background-image: url(../images/nexticon.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  position: relative;
  left: 55px;
}

.pagination-previous {
  background-image: url(../images/previcon.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  position: relative;
  right: 55px;
}

.RecipePagination {
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 500px;
  margin: 0 auto;
  align-items: center;
}

.RecipePagination .selected {
  border-radius: 100% !important;
  width: 46px !important;
  height: 46px !important;
  background-color: rgba(233, 165, 243, 0.15) !important;
  color: #8a7c8d !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.RecipePagination li a {
  margin-right: 10px !important;
  color: #8a7c8d !important;
}

.RecipePagination .pagination-previous.disabled a {
  position: relative;
  left: 40px;
  top: 13px;
}

.RecipePagination .pagination-previous a {
  position: relative;
  left: 40px;
  top: 13px;
}

.filterCategory .swiper-button-next {}

.filterCategory .swiper-button-prev {}

.RecipePagination .pagination-next a {
  position: relative;
  right: 40px;
  top: 13px;
}

/*recipes pagination style end*/
.custom-file__input {
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}

.custom-file__label {
  position: relative;
  display: block;
  width: 100%;
  min-width: 335px;
  min-height: 45px;
  margin: 10px 0;
  padding: 0;
  background: #ffffff;
  /* border: 1px solid #dfdfdf; */
  color: #666666;
  line-height: 45px;
  text-align: center;
  text-transform: none;
  cursor: pointer;
  transition: all 0.3s;
}

.custom-file__input:valid~.custom-file__label {
  border-color: #39b54a;
  background: #39b54a;
  color: #39b54a;
}

.custom-file__input:valid~.custom-file__label:before {
  content: "\f00c";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  color: #ffffff;
  font-family: "FontAwesome";
  line-height: 45px;
}

.stripe_mainbox label {
  padding: 10px 0;
  font-size: 16px;
  font-weight: 400;
}

.card_input {
  width: 100%;
  padding: 10px;
}

.checkIcon {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.card_inputbox {
  width: 60%;
  padding: 10px;
}

#cardDetail .modal-content {
  min-height: 400px;
  padding: 0px 30px;
}

#cardDetail .modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.activePlan {
  background: #f8f9fa;
  border-radius: 50px;
  padding: 13px 31px;
  border: none;
  color: #e9a5f3;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins";
}

/*live streming style start*/
.liveStreaming {
  /* background-image: url("../images/livestremingbg.png"); */
  background-color: #0c0b0c;
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  width: 100%;
  height: 94vh;
  /* padding: 100px 0px; */
  overflow: hidden;
}

.adminvedioContainer {
  width: 100%;
}

.livestreamingOuter {
  position: relative;
}

.livevedio {
  height: 94vh !important;
  width: 100% !important;
}

.App {
  width: 100%;
  position: absolute;
  top: 0;
}

.livestreamingOuter .flexRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 97vh;
  /* position: absolute; */
  /* top: 0px; */
  /* left: 0; */
  width: 100%;
  padding: 30px;
}

.localVideoPlayer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.adminvedioInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
}

.subtextOuter {
  background-color: #fff;
  border-radius: 20px;
  text-align: center;
  padding: 3px 10px;
  margin-right: 10px;
  align-items: center;
  display: flex;
}

.flexRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85vh;
}

.chatBoxInner {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
}

.chatBoxOuter {
  background-color: rgba(255, 255, 255, 10%);
  border-radius: 50px;
  padding: 5px 25px 5px 5px;
  margin-bottom: 10px;
}

.chatBoxOuter:last-child {
  margin-bottom: 0px;
}

.ratingboxInner {
  display: flex;
  align-items: center;
}

.ratingbox {
  background-color: rgba(255, 255, 255, 10%);
  padding: 10px 15px;
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 30%);
  margin-right: 10px;
}

.MassageBox {
  /* filter: blur(10px); */
  padding-top: 0px;
  margin-bottom: 50px;
  background-image: url("../images/livestremblur.png");
}

.msg_box {
  max-width: 60px;
}

.topboxOuter {
  position: absolute;
  left: 49%;
}

.msgBoxHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 30%);
  padding: 10px;
}

.msgBox {
  background-color: rgb(39 36 36 / 86%);
  border: 1px solid rgba(255, 255, 255, 30%);
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}

.replyChatBox {
  background-color: rgba(233, 165, 243, 0.3);
  position: relative;
  left: 20px;
  padding: 5px 5px 5px 25px;
}

.replyChatBox .chatBoxInner {
  flex-direction: row-reverse;
  text-align: end;
}

.chat_Box {
  border-bottom: 1px solid rgba(255, 255, 255, 30%);
  height: 470px;
  overflow-y: auto;
}

.chat_Box::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.chat_Box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.chat_Box::-webkit-scrollbar-thumb {
  background: #888;
}

.user {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.chatInputbox input {
  border-radius: 40px;
  padding: 12px 45px 12px 25px;
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 10%);
  color: rgba(255, 255, 255, 30%);
}

.chatInputbox input::placeholder {
  color: rgba(255, 255, 255, 30%);
}

.send img {
  max-width: 35px;
}

.send {
  position: relative;
  right: 40px;
}

.yellowStar {
  max-width: 18px;
}

.Commenttextarea {
  padding: 20px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 20%);
  border: none;
  color: rgba(255, 255, 255, 10%);
  outline: none;
  margin-bottom: 20px;
  border: 1px solid rgba(255, 255, 255, 30%);
}

.Commenttextarea::placeholder {
  color: rgba(255, 255, 255, 30%);
}

.inputFeild {
  border-radius: 40px;
}

.border-26 {
  border-radius: 26px;
}

.chatBoxSend {
  position: absolute;
  bottom: 110px;
  right: 20px;
}

.chatBoxSend .chatBoxOuter {
  background-color: #222222cf;
  min-width: 350px;
  width: 100%;
}

.arrowRotate {
  transform: rotate(45deg);
  position: relative;
  top: 5px;
}

.chatboxReply {
  justify-content: end;
}

.infoSpinner {
  width: 2rem;
  height: 2rem;
  border-width: 0.2em;
  color: #e9a5f3;
}

.pageLoader {
  width: 4rem;
  height: 4rem;
  border-width: 0.3em;
  color: #e9a5f3;
  position: absolute;
  left: 48%;
  top: 50%;
  /* transform: translateY(-50%); */
}

.booknowBtn_ {
  position: absolute;
  bottom: 40px;
  right: 40px;
}

.commingsoonText_ {
  position: absolute;
  right: 30px;
  background: #ffffff2b;
  padding: 10px 20px;
  border-radius: 35px;
  top: 30px;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}

.agora_video_player {
  position: unset !important;
  height: 100vh !important;
}

.endCallButton {
  color: red;
}

/*privacy policy page style*/
.containerInner {
  border-radius: 30px;
  box-shadow: 10px -25px 60px 0px #e9a5f31a;
  background-color: #fff;
  position: relative;
  z-index: 0;
  margin-top: -150px;
  padding: 35px;
}

.healthyfood_Images {}

.emailBox {
  background: #80808012 !important;
}

.contactus-disable {
  background: #c9c6c9;
  border-radius: 50px;
  padding: 13px 31px;
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins";
  opacity: 0.4;
}

/* Change the color of navigation arrows */
.swiper-button-prev {
  width: 40px;
  height: 40px;
  background: #f1d7f1;
  border-radius: 100%;
  background-image: url("../images/previcon.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: block !important;
}

/* Increase the size of navigation arrows */
.swiper-button-next {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 100%;
  border: 1px solid #e9a5f3;
  background-image: url("../images/nextcircle.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: block !important;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.75 !important;
}

.tradingRecipe .swiper-button-prev {
  background-image: url("../images/prevfortranding.png");
}

.ourCustomers .swiper-button-prev {
  background-image: url("../images/prevfortranding.png");
}

.pinky {
  color: #e9a5f3 !important;
  width: 2rem !important;
  height: 2rem !important;
}

.progress-bar {
  background-color: #e9a5f3;
}

.progress {
  background-color: #fcf3fd;
}

#goLiveModal {
  position: absolute;
  right: 30px;
  width: 100%;
  max-width: 400px !important;
  margin-top: 0;
}

#goLiveModal .primaryBtn {
  font-weight: 600;
  max-width: 161px;
  width: 100%;
}

#goLiveModal .goLiveModalFoot {
  display: flex;
  justify-content: space-between;
  margin-top: 130px;
}

#goLiveModal.profileForm .form-control {
  background: #fcf3fd;
  border-radius: 20px;
  border: none !important;
  padding: 17px 30px 17px 30px;
}

/* Navbar live effect css start  */
html,
body {
  margin: 0 auto;
  color: white;
  font-family: "Montserrat", sans-serif;
}

/* Circle */
.circle {
  height: 41px;
  width: 41px;
  border-radius: 50%;
  background-color: #ff4343;
  transform: translate(85%, -6%);
}

.circle:before,
.circle:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background: rgba(225, 30, 41, 0.5);
  color: #fff;
  cursor: pointer;
}

.circle:before {
  animation: ripple 2s linear infinite;
}

.circle:after {
  animation: ripple 2s linear 1s infinite;
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
    opacity: 1;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

/* Text */
.livetxt {
  text-align: center;
  vertical-align: middle;
  line-height: 39px;
  margin: 0;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
}

/* Navbar live effect css end  */
.slideOnebtn {
  position: absolute;
  top: 16px;
  right: 11px;
  max-width: 125px;
  width: 100%;
  padding: 9px 10px;
  font-size: 10px;
}

.sidebarCancel {
  display: none;
}

.RecipePagination .selected a {
  color: #8a7c8d !important;
}

/* Fav */
.FavRecipies {
  text-align: center;
}

.FavRecipies .nav {
  display: inline-block;
  border-radius: 29.5px;
  background: #fff;
  box-shadow: 0px 4px 35px 0px rgba(233, 165, 243, 0.25);
  padding: 5px;
}

.FavRecipies button {
  color: #8a7c8d;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  background: transparent;
  border: none;
  padding: 14px 30px;
}

.FavRecipies .active {
  border-radius: 50px;
  background: #e9a5f3;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 14px 30px;
}

.innernotify {
  position: relative;
}

.noticlear .editProfileBtn {
  position: static;
}

/* Start  Notification Admin */
#notificationAdmin {
  position: absolute;
  right: 20px;
  top: -30px;
  height: 100vh;
  width: 400px;
}

#notificationAdmin .modal-content {
  max-width: 400px;
  height: 96vh;
}

#notificationAdmin .modal-header {
  justify-content: start;
  border-bottom: 1px solid #dee2e6;
}

.notificationHeading {
  color: #2d2832;
  font-size: 28px;
  font-weight: 600;
}

.modalHeading {
  display: flex;
}

#notificationAdmin figure,
#notificationAdmin p {
  margin: 0;
}

#notificationAdmin .modal-header .btn-close {
  top: auto;
}

.notibell {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.notoficationBox {
  background: #fdf7fe;
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 15px;
}

.flexBox {
  display: flex;
  align-items: center;
}

.notificationBoxHeading {
  color: #2d2832;
  font-size: 15px;
  font-weight: 500;
}

.NotificationComment {
  color: #8a7c8d;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding: 13px 0;
}

.notificationTime {
  color: #8a7c8d;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
}

.userImgBox {
  position: relative;
}

.Notiuserimg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 2rem;
  position: relative;
}

.Notisubuserimg {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  right: 3px;
  bottom: 2px;
  border-radius: 50%;
}

.buttonBox {
  display: flex;
  align-items: center;
}

#notificationAdmin .login-btn {
  padding: 10px 20px;
  font-size: 10px;
}

.circle-btn {
  padding: 5px 11px;
  margin-left: -3px;
  border: none;
}

#notificationAdmin .modal-body {
  height: 100%;
  max-height: 770px;
  overflow: auto;
}

.borderBox {
  border-top: 1px dotted #e9a5f360;
  border-bottom: 1px dotted #e9a5f360;
  margin-top: 2rem;
}

.borderLine {
  background: #e9a5f3;
}

.commentOnrecipeBox {
  border-bottom: 1px dotted #e9a5f3;
}

.commentsHeading {
  color: #2d2832;
  font-size: 42px;
  font-weight: 600;
  line-height: 56px;
}

.commentCount {
  color: #8a7c8d;
  font-size: 14px;
  font-weight: 400;
}

.ratingButton {
  background: #fff;
  border: none;
  padding: 15px 2rem;
  border-radius: 30px;
  margin-right: 10px;
  color: #8a7c8d;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.commeticon {
  width: 56px;
}

.buttonStar {
  width: 26px;
  height: 26px;
  margin: 0 5px;
}

.ratingValue {
  color: #2d2832;
  font-size: 20px;
  font-weight: 500;
}

.commentProfile {
  width: 58px;
  border-radius: 50%;
  margin-right: 20px;
}

.commentsuserName {
  color: #2d2832;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
}

.commentTimeing {
  color: #8a7c8d;
  font-size: 12px;
  font-weight: 400;
}

.userRating {
  width: 20px;
  margin-left: 5px;
}

.removeBtn {
  background: transparent;
  border: none;
  color: #8a7c8d;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.removeIcon {
  margin-right: 5px;
}

.recipeComment {
  color: #8a7c8d;
  font-size: 14px;
  font-weight: 400;
}

.aboutusLarrowcomment {
  position: absolute;
  top: 70px;
  left: 0;
}

.replyBtn {
  background-color: #e9a5f326;
  color: #e9a5f3;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid rgba(233, 165, 243, 0.15);
  padding: 8px 15px;
  border-radius: 30px;
}

.rotatearrowpink {
  margin-left: 6px;
}

.ReplycommentBox .recipeComment {
  padding-left: 8rem;
}

.viewMore {
  color: #e9a5f3;
  font-size: 16px;
  font-weight: 500;
  background: transparent;
  border: none;
}

.accessBtn {
  color: rgba(255, 255, 255, 0.7);
  background: #e9a5f3;
  border: 1px solid #ffffff;
  border-radius: 450px;
  padding: 6px 17px;
  font-size: 13px;
  position: absolute;
  right: -9px;
  top: 16px;
}

.viewMoreIcon {
  margin-left: 8px;
}

/* Start Chat  */
figure {
  margin: 0;
}

.chatUserList {
  filter: drop-shadow(0px 0px 9px rgba(233, 165, 243, 0.25));
  background: #fff;
  border-radius: 20px;
  height: 675px;
}

.commanScrollbar {
  height: 616px;
  max-height: 616px;
  overflow: auto;
}

.chatNodata {
  height: 616px;
  max-height: 616px;
  overflow: auto;
  padding-top: 80%;
}

.chatListHeader {
  border-bottom: 1px solid #e8a5f380;
  padding: 16px 19px 10px;
}

.chatListHeading {
  color: #2d2832;
  font-size: 16px;
  font-weight: 600;
}

.chatUserImg {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-right: 7px;
}

.userlistChat {
  padding: 14px 19px;
  border-bottom: 1px solid #e8a5f333;
  border-radius: 0;
  cursor: pointer;
}

/* .userlistChat:last-child{
  border: none;
} */
.chatUserName {
  color: #2d2832;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
}

.chatTiming {
  color: #8a7c8d;
  font-size: 8px;
  font-weight: 400;
  line-height: 18px;
}

.userMsg {
  color: #8a7c8d;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 260px;
}

.userlistChat.unread {
  background: #fdf6fe80;
  border-left: 2px solid #e9a5f3;
}

.chatListBox {
  width: 100%;
}

.unreadMsg {
  width: 10px;
  height: 10px;
  background: #e9a5f3;
  border-radius: 6px;
}

.userStatus {
  width: 6px;
  height: 6px;
  background: #51be5c;
  border-radius: 3px;
  padding-right: 5px;
  margin-right: 5px;
}

.chatSection {
  padding: 19px 19px 0;
  max-height: 534px;
}

.otherComments {
  background: #fdf7fe;
  padding: 17px 19px;
  border-radius: 20px;
  width: 55%;
  margin-top: 2rem;
}

.commentUser {
  color: #2d2832;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.commentuserTimeing {
  color: #8a7c8d;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 5px;
}

.userComment {
  color: #8a7c8d;
  font-size: 14px;
  font-weight: 400;
  padding-top: 10px;
  margin: 0;
}

.otherCommentsreply {
  background: #e9a5f3;
  padding: 17px 19px;
  border-radius: 20px;
  width: 55%;
  margin-top: 2rem;
  margin-left: auto;
}

.otherCommentsreply .commentUser {
  color: #fff;
}

.otherCommentsreply .commentuserTimeing {
  color: #ffffffb3;
}

.otherCommentsreply .userComment {
  color: #ffffff;
}

.commentBox {
  padding: 0 19px;
  position: relative;
}

.commentBox .yourcommentBox {
  background: #fdf7fe;
  border: none;
  border-radius: 20px;
  padding: 20px 30px;
  resize: none;
  width: 100%;
  color: #8a7c8d;
  font-size: 14px;
  font-weight: 400;
}

.yourcommentBox:focus-visible {
  outline: none;
}

.commentSend {
  position: absolute;
  right: 40px;
  top: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

/* abhishek css start */
.recipeDetailsBtn {
  padding: 8px 8px !important;
  margin-bottom: 0;
  font-size: 11px;
}

.recive-notifications {
  color: #8a7c8d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.switch-toggle {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 36px;
}

.switch-toggle input {
  display: none;
}

.slider-switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a095a2;
  transition: 0.4s;
  border-radius: 34px;
}

.slider-switch-off:before {
  left: 5px !important;
}

.slider-switch:before {
  position: absolute;
  content: "";
  height: 28px;
  width: 28px;
  left: 10px;
  bottom: 4px;
  background-color: #fff;
  transition: 0.4s;
  box-shadow: -2px 0px 8px 0px #00000040;
  border-radius: 50px;
}

input:checked+.slider-switch {
  background-color: #e9a5f3;
}

input:checked+.slider-switch:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.toggle-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 5px;
  margin-left: 8px;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.toggle-off {
  position: absolute;
  right: 3px;
  top: 19px;
}

.supportChatInner {
  background-color: #fcf3fd;
  position: relative;
  justify-content: unset !important;
  display: block !important;
}

.supportChatInner:before {
  background-color: #fcf3fd !important;
  left: -13px !important;
}

.supportChatInner:before {
  position: absolute;
  top: 48%;
  background: #020300;
  width: 25px;
  height: 25px;
  left: -10px;
  content: "";
  transform: rotate(45deg);
}

.chat-bg {
  height: 50px;
  width: 50px;
  display: inline-block;
  border-radius: 50px;
  background-color: #e9a5f3;
  padding: 8px;
  box-shadow: 0px 11px 29px rgba(233, 165, 243, 0.5);
}

.chatInnerSupport {
  display: inline-flex;
  gap: 10px;
  align-items: center;
}

.smiley-text h1 {
  color: #2d2832;
  font-family: Poppins;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

.smiley-text p {
  color: #8a7c8d;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.supportChatHeader {
  border-bottom: 1px solid #e9a5f32e;
  padding: 23px 70px;
}

.comment-msg h1 {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
}

.comment-msg p {
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.comment-msg span {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  word-break: break-all;
}

.comment-msg {
  background-color: #e9a5f3;
  padding: 15px;
  width: 60%;
  /* height: 160px; */
  border-radius: 15px;
}

.comment-right {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 18px;
}

.comment-msg-left {
  background-color: #fbdfff;
  padding: 15px;
  /* height: 160px; */
  width: 60%;
  border-radius: 15px;
}

.comment-msg-left h1 {
  color: #2d2832;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
}

.comment-msg-left p {
  margin: 0;
  color: #8a7c8d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.comment-msg-left span {
  color: #8a7c8d;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.commentchat-input input::placeholder {
  color: #8a7c8d;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  opacity: 1;
}

.commentchat-input input::-ms-input-placeholder {
  color: #8a7c8d;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.commentchat-input input {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 25px 0px rgb(233 165 243 / 15%);
  border: none;
  outline: none;
  padding: 15px 50px 15px 15px;
  width: 100%;
}

.commentchat-input {
  position: relative;
}

.commentchat-input img {
  position: absolute;
  right: 16px;
  top: 16px;
}

.comment-content {
  width: 88%;
  position: absolute;
  bottom: 0;
}

.comment-left {
  margin-bottom: 30px;
}

.comment-content {
  padding: 0 0 23px 70px;
}

.chat-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.chat-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chat-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

.chat-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chat-scrollbar {
  height: 76vh;
  overflow: auto;
  padding-right: 5px;
}

.hide-icons .swiper-button-prev,
.hide-icons .swiper-button-next {
  display: none !important;
}

.subscription-inner {
  width: 62%;
  margin: 0 auto;
}

.sliderOuterScrollbar .swiper-scrollbar.swiper-scrollbar-horizontal {
  width: calc(88% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
  top: 94%;
  height: 17px;
  margin-left: 17px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  background: none;
  left: 10px;
}

.sliderOuterScrollbar .swiper-scrollbar-drag {
  background: #8a7c8d;
  height: 5px;
  top: 3px;
  margin: 0 -4px;
}

.classbox-outer {
  max-height: 564px;
  position: relative;
}

.recipe-bgmain {
  margin-top: -65px;
}

.textArea-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.textArea-delete i {
  color: #fff;
  background-color: #e9a5f3;
  padding: 10px;
  font-size: 21px;
  cursor: pointer;
  border-radius: 50px;
}

.profile-photoSmall {
  display: none;
}

.profile-photoSmall img {
  border-radius: 20px;
}

.updateProfileImg-smalll {
  display: none;
}


.recipeBannerRes {
  height: 430px;
  padding: 0;
}

.slideOnebtnViewResp {
  background-color: rgb(0 0 0 / 9%);
}

.paginationBoxResp {
  padding: 0 !important;
}

.circle-btnResp {
  padding: 15px 20px !important;
}

.live-end-camera {
  position: absolute;
  left: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  bottom: 8px;
}

.notificaton-first-img {
  height: 60px;
  width: 60px;
}

.notificaton-first-img img {
  border-radius: 50px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.notificaton-second-img {
  height: 30px;
  width: 30px;
  position: absolute;
  right: -3px;
  bottom: 3px;
}

.notification-images {
  position: relative;
}

.notificaton-second-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
  border: 2px solid #fff;
}

.notification-images-text {
  display: flex;
  gap: 15px;
  align-items: self-start;
}

.commentchat-input-bg {
  width: 95%;
  margin: 0 auto;
}

.commentchat-input-bg input {
  background: #fdf7fe;
  padding: 20px 15px;
}

.commentchat-input.commentchat-input-bg img {
  position: absolute;
  right: 16px;
  top: 20px;
}

.nodata-found-img {
  width: 386px;
  height: 318px;
  margin: 0 auto;
}

/* .swiperClassResp .swiper-slide-active {
  max-width: unset !important;
} */
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.select-camera {
  background-color: #e9a5f3;
  border-radius: 50px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.select-camera:hover {
  border: none !important;
}

.chatInputboxResp {
  position: relative;
}

.chatInputboxResp input {
  width: 100%;
}

.send-live-msg {
  position: absolute;
  right: 22px;
}

.send-live-msg {
  position: absolute;
  right: 22px;
  background: #e9a5f3;
  border: none;
  padding: 7px;
  border-radius: 50px;
  top: 20px;
}

.liveTextResp {
  z-index: 0;
}

.admin-profile-dropdown {
  border-radius: 50px;
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 25px 0px #e0a6e93b;
  padding: 5px;
  cursor: pointer;
}

.admin-profile-dropdown button.btn.dropdown-toggle {
  box-shadow: none;
}

.profile-dropdown-btn {
  display: block;
}

.admin-profile-dropdown .user_img {
  margin-right: 15px;
}

.trading-recipe-resp .swiper-wrapper {
  padding-bottom: 160px;
}

.subscription-loader {
  padding: 120px 0;
}

.graphCon-dashboard {
  height: calc(100vh - 352px) !important;
}

.planBoxOuterNew {
  background-image: url("../images/subs-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  box-shadow: 10px 15px 40px 0px #e9a5f31a;
  border-radius: 30px;
  width: 100%;
}

.viewmoreRespBtn {
  border: none;
  background: transparent;
  font-weight: 500;
  margin-top: 33px;
}

.bigHeadingResp {
  margin-top: 46px;
}

.title-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recipe-tag {
  color: #ffffff;
  background: #0dcc2d;
  border: 1px solid #ffffff;
  border-radius: 7px;
  padding: 3px 12px;
  font-size: 13px;
  position: absolute;
  top: 13px;
  right: 11px;
  font-weight: 500;
}

.recipe-tagPaid {
  color: #ffffff;
  background: #e53133;
  border: 1px solid #ffffff;
  border-radius: 7px;
  padding: 3px 12px;
  font-size: 13px;
  position: absolute;
  top: 13px;
  right: 11px;
  font-weight: 500;
}

/* .accessBtnClasses {
  color: rgba(255, 255, 255, 0.7);
  background: #0dcc2d;
  border: 1px solid #ffffff;
  border-radius: 450px;
  padding: 6px 17px;
  font-size: 13px;
  position: absolute;
  top: 23px;
  right: 23px;
}
.accessBtnClassesPaid {
  color: #ffffff;
  background: #e53133;
  border: 1px solid #ffffff;
  border-radius: 7px;
  padding: 3px 12px;
  font-size: 13px;
  position: absolute;
  top: 13px;
  right: 20px;
  font-weight: 500;
} */

.bas-toggle-button {
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
  color: #e9a5f3;
  border-radius: 12px;
  font-size: 21px;
}

.bas-toggle-button {
  display: none;
}

.mySwalClass .swal-button.swal-button--cancel {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #e53133;
  font-size: 12px;
  color: #ffffff;
}

/* abhishek css end */
.mySwalClass .swal-button.swal-button--confirm {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #0dcc2d;
  font-size: 12px;
}

.mySwalClass .swal-icon--info:after,
.swal-icon--info:before {
  content: "";
  background-color: #0dcc2d !important;
}

.mySwalClass .swal-icon--info:after,
.swal-icon--info::after {
  content: "";
  background-color: #0dcc2d !important;
}

.mySwalClass .swal-icon.swal-icon--info {
  border-color: #0dcc2d;
}

.swal2-title {
  color: #e9a5f3;
}

.countdown-container {
  text-align: center;
}

.countdown-timer {
  font-size: 24px;
  font-weight: bold;
  color: #e9a5f3;
}

#timer-container {
  border-radius: 50%;
  width: 120px;
  /* Adjust the width and height as needed */
  height: 120px;
  background-color: #eee;
  /* Set background color */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  /* Adjust margin as needed */
}

/* p {
  margin-top: 15px;
  margin-bottom: 1rem;
} */
.innersearchbar.inner-controls {
  display: flex;
  align-items: center;
  gap: 20px;
}

.inner-controls .customSelect {
  width: 41%;
  display: flex;
  align-items: center;
  gap: 4px;
}

.inner-controls .dataTables_length {
  width: 20%;
  display: flex;
  align-items: center;
  gap: 7px;
}

.inner-controls .add-recipe-btn {
  width: 40%;
}

.inner-controls .add-recipe-btn a {
  display: flex;
  align-items: center;
}

.inner-controls .login-btn {
  width: 137px;
  padding: 13px 17px;
}

.classes-select-items {
  flex-wrap: wrap;
}

.detail-recipe-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  margin-bottom: 11px;
}

.addClassCross-resp {
  width: 30px;
  position: absolute;
  top: -12px;
  right: -12px;
}

.InputOuter-outer {
  margin-left: 10px;
}

.cancle-update-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.userimgfeild {
  font-size: 14px;
  color: #8a7c8d;
}

/* swal */
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #e9a5f3 !important;
  color: #fff;
  font-size: 1em;
}

.swiperForClasses {
  width: 82% !important;
  position: static !important;
  margin-right: auto !important;
}

.card-modal-loading {
  width: 3rem;
  height: 3rem;
  border-width: 0.3em;
  color: #e9a5f3;
}

.card-loader-outer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-us-textarea {
  min-height: 60px;
}

/*Priyanka css start*/

.perfectbreakfast .classdetailbg img {
  border-radius: 10px;
}

.cursor-pointer-trash {
  cursor: pointer;
}

.remove-comment-text {
  color: #8A7C8D;
  font-size: 14px;
  margin-left: 10px;
}

.remove-comment-outer {
  display: inline-block;
  height: 23px;
  cursor: pointer;
}

.attach-pointer {
  cursor: pointer;
}

.positionpaltes_ {
  position: absolute;
  bottom: -131px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 58%;
}

.recipebannerimg1 {
  position: absolute;
  left: 0;
  bottom: 65px;
}

.recipebannerimg3 {
  position: absolute;
  right: 0;
  bottom: 62px;
}

.recipebannerimg2 {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
}

#cardDetail .modal-content {
  min-height: 311px;
  padding: 30px 30px 0;
  background: #fef8ff;
}

#cardDetail .modal-content .headingSmall_ {
  color: #212529;
  font-size: 34px;
  font-weight: 600;
}

#cardDetail.modal-dialog {
  max-width: 514px !important;
}


#cardDetail .modal-content:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 50%;
  background-image: url("../images/bglines.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  z-index: 0;
  right: 0;
}

button.swal2-confirm.swal2-styled {
  outline: none !important;
  box-shadow: none !important;
}

.AboutUs_starstyling_rating {
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 7px;
}

.foodboxInner .bigText {
  max-width: 214px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 100%;
}

.foodBoxOuter {
  background: #fcf3fd;
  border-radius: 22px;
  height: 100%;
  margin-left: 40px;
  position: relative;
  min-height: 212px;
}

.withOutClass {
  padding-top: 90px;
}

#notificationHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

#notificationBody {
  margin-top: 10px;
  text-align: center;
}

#imageContainer {
  display: flex;
  align-items: center;
  height: 100px;
  object-fit: contain;
}

.contentCommon .graphCon.graphCon-dashboard tr:nth-child(2) td {
  padding-top: 12px !important;
}

.contentCommon .graphCon.graphCon-dashboard .arrowRotate {
  top: 18px;
}