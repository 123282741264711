@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .footerList {
    width: 35%;
  }

  .footerListRight {
    width: 65%;
  }

  .downloadAppsOuter {
    height: 925px;
  }
}

@media (min-width: 1620px) {
  .rightShape {
    left: 65px;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1599px) {
  .navbar-nav {
    margin-right: 90px;
  }
}

@media only screen and (min-width: 1199px) and (max-width: 1400px) {
  .navbar-nav {
    margin-right: 30px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1080px) {
  .innersearchbar.inner-controls {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 608px) {
  .downloadApps-bg .text {
    font-size: 14px!important;
}
  .whyChooseContent .text {
    font-size: 13px !important;
}
  .innersearchbar.inner-controls {
    margin-top: 10px;
  }

  .inner-controls .customSelect select {
    padding: 6px 21px 6px 15px;
  }

  .text {
    font-size: 12px !important;
}

  .inner-controls .login-btn {
    width: 87px;
    padding: 13px 12px;
    font-size: 8px;
  }

  .recipe-contols-outer .recipeBtnCircle {
    padding: 7px 12px;
  }
}
@media (max-width: 1600px) {

}
@media (max-width: 1399px) {
  .seeFullrecipe {
    padding: 6px 12px;
  }

  .login-btn {
    padding: 13px 15px;
    font-size: 12px;
  }

  .joinusBtn {
    padding: 13px 31px;
  }

  .serachBar input {
    width: 250px;
  }

  .footerList {
    width: 35%;
  }

  .footerListRight {
    width: 65%;
  }

  .chat_Box {
    height: 470px;
  }

  .topboxOuter {
    left: 38%;
  }

  .termsBanner {
    height: 515px !important;
  }

  .banner-section:before {
    max-width: 700px;
  }

  .contactUs {
    height: 435px !important;
  }

  .thanksBanner {
    height: 480px !important;
  }

  .ownerdetail_Lynnette:before {
    right: 0;
  }
}

@media (max-width: 1366px) {
  .downloadAppsOuter {
    min-height: 1057px;
}
  .editProfile {
    padding: 0px 45px !important;
  }

  .nav_link a {
    font-size: 15px !important;
  }

  .StartStremBtn {
    padding: 13px 10px;
    font-size: 10px;
  }

  .StartStremCircle {
    padding: 6px 12px;
  }

  .StartStremCircle img {
    width: 10px !important;
  }

  .courseDurationDetialsClass {
    flex-wrap: wrap;
  }

  /* rimpi */
  .dashboardStream.graphconText {
    font-size: 10px;
  }

  /* rimpi */
}

@media (max-width: 1280px) {
  .recipe-tagPaid {
    top: 8px;
    right: 6px;
}
  .recipe-tag {
    top: 8px;
    right: 6px;
}

  .courseDurationDetials li p {
    font-size: 12px !important;
  }

  .courseDurationDetials li h6 {
    font-size: 12px !important;
  }

  .pinkBox {
    min-width: 35px !important;
    height: 35px !important;
  }

  .dashCardHeading h2 {
    font-size: 13px !important;
  }

  .dashCard {
    align-items: start;
  }

  .rightScrollBox {
    padding: 10px 5px !important;
  }

  .rightScrollInner {
    padding: 10px 8px !important;
  }

  /* frontend_R */
  .dashboardStream.graphconText {
    font-size: 10px !important;
  }

  .digitBox {
    font-size: 11px !important;
  }

  /* frontend_R */
}

@media only screen and (min-width: 992px) and (max-width: 1250px) {
  .login-btn {
    padding: 16px 13px;
  }

  .StartStremBtn {
    padding: 14px 8px !important;
  }

  .sendButton {
    padding: 13px 31px !important;
  }

  .editProfile {
    padding: 13px 31px !important;
  }

  .footerInput input {
    max-width: 200px;
  }

  .logininner {
    padding: 15px 20px;
  }

  .infocicleOuter {
    flex-direction: column;
    align-items: start !important;
  }

  .infoCirle {
    margin-bottom: 10px;
  }
}
@media (max-width: 1210px) {
.digitBox {
	padding: 0px 6px;
	margin-left: 5px;
}
}
@media (max-width: 1199px) {
  .inrecipeout {
    width: 18%!important;
    margin-bottom: 20px!important;
  }
  .chat-scrollbar {
    height: 76vh;
}
  .recipeDetailsBtn {
    font-size: 10px;
}
  .bigText.home {
    font-size: 13px;
}
  .recipe-tagPaid {
    right: -13px;
    top: -2px;
}
.recipe-tag {
  top: -2px;
  right: -16px;
 
}
  .recipeBanner.favoritesBanner .bigHeading {
    font-size: 45px;
}
  .recipebannerimg3 {
    bottom: 82px;
    width: 25%;
}
.recipebannerimg1 {
  bottom: 84px;
  width: 25%;
}
.recipebannerimg2 {
  bottom: 30px;
  width: 32%;
}
  .navbar-nav li {
    padding: 0px 20px;
  }

  .circle {
    transform: translate(-9%, -9%);
  }

  .profileBtnOuter {
    left: 11px;
  }

  .dropdown-menu.ProfileDropdown li {
    padding: 10px 0px;
  }

  .tradingRecipe {
    min-height: 850px;
  }

  .footerInput input {
    max-width: 200px;
  }

  .topFooter h4 {
    font-size: 25px;
  }
}
@media (max-width: 1175px) {
.contentCommon {
  padding: 21px 25px!important;
}
.contentCommon .paginationBox {
  bottom: 30px!important;
}
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .seeFullrecipe {
    padding: 6px 10px;
  }

  .subheading {
    font-size: 30px;
  }

  .reportsOuter li {
    min-width: 130px;
    width: 100%;
    margin-right: 10px;
  }

  .foodBox {
    padding: 0;
    position: relative;
    right: 17px;
}

  .footerList p {
    padding: 0px 20px !important;
  }

  .contactus_form {
    padding-left: 150px;
  }

  .foodInner {
    padding: 20px 5px 20px 0px;
  }

  .banner-section:before {
    max-width: 600px;
    height: 685px;
  }

  .tab span {
    font-size: 13px;
  }

  .appBtn {
    padding: 16px;
  }

  .appBtnOuter {
    padding-top: 0 !important;
  }

  .downloadAppsOuter {
    min-height: 806px;
    padding: 0;
    background-size: 100% 100%;
  }
}

@media (min-width: 991px) {
  .mobileLive {
    display: none;
    padding: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .flex-row {
    flex-direction: column !important;
  }

  .sliderOuterScrollbar .swiper-scrollbar.swiper-scrollbar-horizontal {
    width: calc(80% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
  }

  .tradingRecipe:before {
    top: -80px;
    width: 155px;
    height: 165px;
  }

  .profileForm .form-group {
    padding-right: 20px;
  }

  .courseDurationDetialsClass {
    flex-wrap: nowrap !important;
  }

  .planTitle {
    width: 200px !important;
    margin-right: 0;
  }

  .topFooter h4 {
    font-size: 21px;
  }

  .rightArrowOuter {
    padding-right: 10px;
  }

  .courseDetailsInner {
    width: auto !important;
  }

  /* .graphCon {
    max-height: 480px !important;
  } */

  .pinkBoxOuter {
    align-items: self-start !important;
  }

  .space {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .courseCard {
    overflow: auto;
  }

  .courseDurationDetials li {
    width: 100%;
    min-width: 110px !important;
    margin-right: 10px !important;
  }

  .courseDurationDetials li p {
    font-size: 11px !important;
  }

  .inputOut {
    flex-direction: column;
  }

  .courseDurationDetials li h6 {
    font-size: 12px !important;
  }

  .searchDataTable .serachBar {
    margin-bottom: 15px;
  }

  .pinkBoxOuter h2 {
    font-size: 13px !important;
  }

  .commonBody {
    min-width: calc(100% - 180px) !important;
    margin-left: 180px !important;
  }

  .nav_link {
    padding: 0 25px !important;
  }

  .sidebar {
    max-width: 180px !important;
  }

  .signUpBtn {
    padding: 13px !important;
  }

  .banner-section .swiper-button-prev {
    left: 85%;
    top: 94%;
  }

  .twoMobileImg {
    bottom: 70px;
  }

  .appBtn {
    padding: 16px 18px;
  }
}

@media (max-width: 768px) {
  .commonBody {
    margin-left: 0 !important;
  }

  .sliderOuterScrollbar .swiper-scrollbar.swiper-scrollbar-horizontal {
    width: calc(73% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
  }

  .swiperClass {
    width: 80% !important;
  }

  .courseDurationDetialsClass {
    flex-wrap: nowrap !important;
  }

  .courseDurationDetials li {
    width: 100%;
    min-width: 110px !important;
    margin-right: 10px !important;
  }

  .sidebarCancel {
    display: block;
    position: absolute;
    right: 38px;
    bottom: 92px;
  }
  .RecipesSwiper {
    background-size: 133% 100%;
}
}

@media (max-width: 992px) {
  .logininner {
    padding: 15px 10px;
  }

  /* .favouriteIcon {
    margin-right: 7px;
  } */

  .desktopLive {
    padding: 0;
  }

  .seeFullrecipe {
    padding: 6px 10px;
  }

  .swiperClass {
    width: 80%;
  }
}
@media (max-width: 1050px) {
.inrecipeout {
	width: 25% !important;
	margin-bottom: 20px !important;
}
}
@media (max-width: 920px) {
.innerCourseCart_ {
  height: calc(100vh - 372px)!important;
}
}
@media (max-width: 991px) {
  .navbar {
    z-index: 9;
}
  .passwordouter {
    height: 100vh;
    justify-content: center!important;
}
  .insidecontent_ {
    height: calc(100vh - 121px)!important;
}
  .contentCommon.insidecontent_ .courseDurationDetialsClass {
    flex-wrap: wrap !important;
  } 
  .inrecipeout {
    width: 30% !important;
    margin-bottom: 15px;
  }

  .detailRecipeCardInner {

    justify-content: start!important;
 
  }
  .notification-toggle-outer {

    position: absolute;
    right: -93px;
    bottom: 2px;
}
  .heightouter .formTabs .loginForm {
    position: relative;
}  .heightouter .formTabs a {
    justify-content: start;
}
  .chat-scrollbar {
    height: 76vh;
}
  .ownerdetail_Lynnette:before {
    top: 47px;
    right: -38px;
    max-width: 155px;
    height: 163px;
}
  .recipe-tagPaid {
    right: 7px;
    top: 9px;
}
  .recipe-tag {
    top: 9px;
    right: 7px;
}
  .subscription-inner {
    width: 80%;
}


  .recipeBanner.favoritesBanner .bigHeading {
    font-size: 34px;
}
  .downloadApps-bg {
    background-size: 100% 87%;
    top: 51px;
}
.navbar-nav {
  background-color: #fce9ff;
  padding: 20px 20px 50px;
  border-radius: 10px;
}

  .mobileLive {
    display: block;
  }

  .desktopLive {
    display: none;
  }

  .dashboardContent {
    height: auto !important;
  }

  .inputOuter {
    margin-right: 0 !important;
  }

  .recipebgimg img {
    max-height: 330px !important;
  }

  .vedio {
    height: 472px !important;
    object-fit: cover;
}

  .courseCardConRecipe {
    padding-right: 0px !important;
  }



  #staffsTable_length .paginationBox .selected {
    margin-right: 13px;
  }

  /* frontend_R */
  .bigHeadingResp {
    margin-top: 0;
  }

  .recipeBanner {
    height: 365px;
    padding: 33px 0px;
  }

  .notifyOuter {
    max-height: 566px !important;
  }

  .imgouter {
    display: none;
  }

  .navbar-nav .dropdown-menu {
    transform: translate(-55px, 10px);
  }

  .contactus_form {
    padding: 30px;
    margin-top: 30px;
  }

  .foodBoxOuter {
    margin-left: 40px;
  }
  .RecipesPage .foodBoxOuter {
    width: 93%;
}
  .tradingRecipe .swiper-button-prev {
    left: 85%;
  }

  .copyrightBottom {
    text-align: center;
    padding-top: 50px;
  }

  .footerLink {
    width: 50%;
  }

  .footerListOuter {
    flex-direction: column;
  }

  .footerListRight {
    width: 100%;
  }

  .footerList {
    width: 100%;
  }

  .myProflieOuter label {
    text-align: center;
  }

  .verticalimg {
    display: block;
    margin-bottom: 30px;
  }

  .tabnavouter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .tabnavouter li {
    max-width: 220px;
  }

  .verticalRecipeImg {
    display: none;
  }

  .myProflieOuter input {
    text-align: center;
  }

  .myProflieOuter .form-group {
    text-align: center;
  }

  .myProflieOuter a {
    text-align: center;
  }

  .top {
    margin: 0 auto 20px;
  }

  .planBoxOuter {
    max-width: 100%;
  }

  .leftmapbox {
    max-width: 358px;
    margin: 0 auto;
  }

  .formTabs {
    display: block !important;
    padding: 0px 0px;
  }

  .formTabsOuter .formTabs {
    position: absolute;
    top: 0;
    left: 54px;
}

  .courseDurationDetialsClass {
    flex-wrap: nowrap !important;
  }

  .changePasswordOuter {
    display: none;
  }

  .formTabs .loginForm {
    padding: 0px;
  }

  .copyrightBottom {
    display: block !important;
  }

  .streamContent {
    margin-left: 0;
    margin-top: 195px;
  }

  .downloadAppsOuter {
    background-image: none;
    padding-top: 40px;
    min-height: 1044px;
}

  .bigHeading {
    font-size: 30px;
    line-height: 35px;
    padding-bottom: 0 !important;
  }

  .downloadApps {
    top: 0px;
    padding: 30px 48px 45px 48px;
    max-height: 380px;
  }

  .banner-section:before {
    max-width: 490px;
    height: 600px;
  }

  .profile-photoSmall {
    width: 130px;
    display: block;
  }

  .swiper-slide.swiper-slide-active .Slide:before {
    width: 215px;
  }

  .updateProfileImg-smalll {
    margin: 0 auto;
    width: 130px;
    display: block;
  }

  .updateProfileImg-smalll img {
    border-radius: 20px;
  }

  /* frontend_R */
  .contentCommon .paginationBox {
    right: 6px !important;
    bottom: 30px !important;
  }

  /* frontend_R */
}
@media (max-width: 850px) {
  .profileForm .form-label {
    font-size: 15px;
  }
  .inrecipeout {
    width: 44% !important;
  }
  .positionpaltes_ {
    bottom: -112px;
  }
  .RecipesPage .foodBoxOuter {
    width: 89%;
  }
  .profileTable tr td:last-child {
    min-width: 133px;
}
.profileTable tr td:nth-child(4) {
  min-width: 200px;
}
  }
@media (max-width: 767px) {
  .termsBanner {
    height: 411px !important;
}
  .perfectbreakfast .container.p-0 .contactUsforminput_field {
    margin-bottom: 12px;
}
  .RecipesPage .foodBoxOuter {
    width: 94%;
}

  .formTabsOuter .formTabs {
    left: 23px;
}
  .imgouter.editProfile.commonheight {
    height: 100vh!important;
    justify-content: space-between!important;
}

  .notifyOuter {
    max-height: 653px !important;
}

  .classesSlider .container {
    max-width: 700px!important;
}
  .classtabsOuter {
    margin-top: 17%!important;
}
.sliderBoxOuter {
  width: 679px;
}
  /* .contactUsforminput_field {
    margin-bottom: 20px;
} */
  .ourRecipe.RecipesPage .tabnav li {
    width: 49%;
}
.ourRecipe.RecipesPage .tabnav li .tab {
  width: 92%;
  max-width: initial;
}
  .recipebannerimg3 {
    bottom: 79px;
    width: 29%;
} 
.recipebannerimg2 {
  bottom: 24px;
  width: 38%;
  right: 0;
}
  .positionpaltes_ {
    bottom: -138px;
    width: 66%;
}
  .recipebannerimg1 {
    bottom: 77px;
    width: 29%;
}
  .ourCustomers {
    padding: 109px 0px 50px;
}
  .downloadApps-bg {
    top: -28px;
}
  .SignupContent {
    width: 100%;
  }

  .bas-toggle-button {
    display: block;
  }

  .downloadAppsOuter {
    min-height: 1065px;
}

.courseCard {
  overflow-y: auto;
}

  .bigHeading {
    font-size: 28px;
    line-height: 40px;
  }

  .banner-section {
    padding-top: 130px;
  }

  .upcomingclassarrow {
    top: -100px;
  }

  .instrutOut {
    flex-direction: column;
  }

  .InstructorOuter {
    left: 0;
    padding-top: 30px;
    padding-right: 35px;
  }

  .recipeBanner {
    height: 315px;
  }

  .top-head h2 {
    padding-bottom: 10px !important;
    padding-top: 25px !important;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 20px;
  }

  /* .recipeBanner p{
    padding: 0px 50px !important;
  } */
  .subscriptionOuter {
    margin-top: -46px;
}

  .Aboutusrecommendrecipe {
    flex-direction: column;
  }

  .likeDislike {
    padding-left: 0px;
    padding-right: 22px;
}
  .ratinglike_Options {
    display: flex;
    align-items: center;
  }

  .AboutUs_Startrating {
    width: 15%;
  }

  .AboutUs_likeComments {
    flex-direction: row;
    width: 270px;
    justify-content: space-around;
    margin-bottom: 0;
    margin-right: 20px;
  }

  .Aboutus_heartimg {
    width: 60px;
    height: 60px;
  }

  .tradingRecipe .swiper-button-prev {
    left: 80%;
  }

  .tradingRecipe:before {
    top: -70px;
    width: 142px;
    height: 150px;
  }

  .classBox {
    margin: 0 auto;
  }

  .contentSection {
    padding: 50px 0px;
  }

  .favoritesBanner {
    height: 358px;
}

  .contactus_form {
    padding: 50px 30px;
    margin-top: 30px;
  }

  .tradingRecipe:before {}

  .aboutBanner {
    height: 335px !important;
  }

  .healthyfood_Images {
    margin-top: -65px;
  }

  .banner-section:after {
    content: none;
  }

  .ourCustomers:after {
    width: 550px;
    height: 380px;
    top: -235px;
  }

  .ourRecipe {
    padding: 60px 0px;
  }

  .twoMobileImg {
    bottom: 10px;
  }

  .banner-content {
    max-width: 100%;
    text-align: center;
  }

  .banner-section .swiper-slide.swiper-slide-active {
    text-align: center;
  }

  .topFooter {
    text-align: center;
  }

  .ourCustomers:before {
    width: 275px;
    height: 275px;
    top: -185px;
  }

  .subheading {
    font-size: 32px;
  }

  .foodBox {
    padding-left: 0;
  }

  .streamsLive {
    padding-bottom: 70px;
  }

  .whyChooseus {
    padding: 50px 0px;
  }

  .whyChooseContent {
    margin: 0 auto 50px;
  }

  .rectangleImg {
    margin: 50px auto;
  }

  .banner-section:before {
    content: none;
  }

  .tabnav li {
    max-width: 100%;
  }
  .ourRecipe .tabnavouter li {
    max-width: initial;
    width: 33%;
}

  .heading {
    font-size: 25px;
  }

  .editProfile {
    padding: 0px 8px !important;
}
.editProfileBtn {
  padding: 9px 13px !important;
}
.login-btn {
  font-size: 11px;
}
.editIcon {
  right: 3px;
}
.editIcon img {
  width: 15px;
}
.heightouter {
  height: 100vh!important;
}

  .editProfile {
    justify-content: flex-end!important;
    height: 100%;
}

  /* frontend_R */
  .userTable td {
    max-width: 18ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .contentCommon .paginationBox {
    right: 6px !important;
    bottom: 34px !important;
  }
  .paginationBox li a {
    margin-right: 17px;
  }
  .paginationBox .selected {
    margin-right: 17px;
  }
 
  /* frontend_R */
}
@media (max-width: 640px) {
  .sliderImgOuter img {
    width: 584px;
  
}
  .sliderBoxOuter {
    width: 575px;
}
  .classesSlider .container {
    max-width: 599px!important;
}
  .contentCommon .paginationBox {
    right: 0 !important;
    bottom: 16px !important;
    left: 0;
    margin: 0 auto;
    text-align: center;
  }

#staffsTable_length {
  padding-top: 0px !important;
}
.RecipesPage .foodBoxOuter {
  width: 93%;
}
}
@media (max-width: 575px) {
  .founder {
    background-size: 152% 100%;
    padding: 0px 0px 39px;
}
  .SubscriptionBoxInner .login-btn {
    padding: 13px 31px;
}
  .space {
    padding: 20px 16px 40px !important;
}

  .myProfile .profileBig {
    width: 83px!important;
    height: 83px !important;
    margin: 0px 10px 0 0 !important;
}
  .classtabsOuter {
    margin-top: 5%!important;
}

  #notificationAdmin {
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
}
  .toggle-off {
    top: 16px;
}
  .slider-switch:before {
    height: 22px;
    width: 22px;
    left: 11px;
}
  .switch-toggle {
    width: 64px;
    height: 30px;
}
  .ownerdetail_Lynnette:before {
    right: 8px;
}

  .positionpaltes_ {
    bottom: -113px;
    width: 73%;
}
 
  .subscription-inner {
    width: 100%;
}
  .ourRecipe.RecipesPage .tabnav li .tab {
    width: 100%;
}
  .tradingRecipe .swiper-button-next {
    right: 20px;
}
  .ourRecipe .tabnavouter li {
    width: 49%;
}
  .commingsoonText_ {
    padding: 9px 16px;
    font-size: 11px;  
}
  .perfectbreakfast .ourCustomers {
    padding: 34px 0px 50px;
}
  .RecipesSwiper {
    background-size: 146% 100%;
}
.upcomingclassarrow {
  top: -65px;
}
  .cookingOuter {
    padding-right: 16px;
}
  .Aboutus_clockcook_showimg {
    width: 28px;
}
  .appBtn {
    padding: 9px 20px;
  }

  .downloadAppsOuter {
    min-height: 896px!important;
}

  .extraSmallText {
    font-size: 6px;
  }

  .ourCustomers {
    margin: 72px 0px 0px;
    padding: 120px 0px 50px;
  }

  .ourCustomers:before {
    width: 230px;
    height: 230px;
    top: -100px;
  }

  .ourCustomers:after {
    width: 393px;
    height: 273px;
    top: -121px;
  }

  .ourCustomers:after {
    left: 50%;
  }

  .swiperClass {
    width: 75% !important;
  }

  .streamContent {
    margin-top: 220px;
  }

  .ClassRecipeTabs {
    padding-top: 60px;
  }

  .classBox {
    max-width: 528px;
    max-height: 535px;
  }

  .downloadApps {
    border-radius: 40px !important;
  }

  .classVedioImg img {
    width: 504px !important;
    object-fit: fill;
  }

  .sliderImgOuter img {
    width: 543px;
    max-height: 345px;
}

  .sliderBoxOuter {
    width: 100%;
    max-height: fit-content;
  }
    .swiperClass {
      width: 67% !important;
    }

    .swiperClass {
      width: 75%;
      position: static !important;
    }

    .navBarText {
      max-width: 12ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }

    .headeRightUser {
      max-width: 9ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }

    .updateFormGroup {
      padding: 10px !important;
      margin-bottom: 8px !important;
    }

    .formTabs {
      padding: 0px;
    }

    .filterBottomBorder {
      margin-bottom: 10px;
    }

    .downloadApps {
      padding: 20px;
      max-height: 295px;
      border-radius: 40px;
    }

    .whyChooseus {
      padding: 10px 0px;
    }

    .appBtnOuter {
      padding-top: 10px !important;
    }

    .streamContent {
      margin-top: 250px;
      margin-right: 0;
      padding: 0px 20px;
    }

    .founderimg_Lynnette {
      margin: 0 auto;
    }

   

    .downloadAppsOuter {
      padding: 50px 0px;
      min-height: 880px;
    }

    .twoMobileImg {
      bottom: 0;
    }

    .appBtn {
      padding: 7px 18px;
    }

    .notifyInner {
      flex-direction: column;
    }

    .notifyContent {
      width: 100% !important;
    }

    .buttonOuter p {
      text-align: center;
    }

    .buttonOuter {
      text-align: center;
      width: 100%;
    }

    .notifyImg {
      margin-bottom: 15px;
      margin-right: 0 !important;
    }

    .login-btn {
      font-size: 11px;
    }
    .loginpage .login-btn {
      font-size: 13px;
  }
  

  /* frontend_R */
  .paginationBox .selected {
    margin-right: 10px;
  }

  .paginationBox li a {
    margin-right: 10px;
  }

  .navBarText {
    font-size: 20px !important;
  }

  .contentCommon table td {
    max-width: 16ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .paginationBox li a {
    margin-right: 10px;
  }

  .paginationBox .selected {
    margin-right: 15px;
  }

  .contentCommon .paginationBox {
    right: 6px !important;
    bottom: 14px !important;
  }

  .tab-content.contentCommon .paginationBox {
    right: 6px !important;
    bottom: 17px !important;
  }

  .tab-content.paginationBox {
    margin-right: 11px;
  }

  .detailRecipeCardInner {
    display: block;
  }

  .commentCount {
    font-size: 10px;
  }

  .commentsHeading {
    font-size: 17px;
  }

  /* frontend_R */
}

@media only screen and (min-width: 480px) and (max-width: 727px) {
  .class-content-resp .customSelect {
    width: 30%;
  }

  .add-classess-btn {
    width: 40%;
    margin-top: 16px;
  }

  .classes-select-items {
    flex-wrap: nowrap;
    margin-top: 16px;
  }

  .class-content-resp .customSelect select {
    width: 100%;
  }

  .class-content-resp .serachBar input {
    max-width: 100%;
  }

  .class-content-resp .serachBar {
    width: 100%;
  }
}
@media (max-width: 545px) {
.sliderImgOuter img {
  width: 510px;
}
}
@media (max-width: 500px) {
  .recipeBanner.termsBanner {
    height: 384px!important;
}

  .positionpaltes_ {
    bottom: -103px;
}
.recipeBanner {
  height: 264px!important;
}
.navbar-brand img {
  width: 82px;
}
}
@media (max-width: 480px) {
  .recipeBanner.termsBanner {
    height: 331px!important;
}

  #cardDetail .modalHeader_ .headingSmall_ {
    font-size: 28px;
}
  .Aboutus_foodimage {
    height: 68vh;
}

  .RecipesPage .foodBoxOuter {
    width: 88%;
}
  .login-btn {
    padding: 13px 7px;
}
.loginpage .login-btn {
  padding: 13px 30px;
}
.sliderBoxInner .login-btn {
  padding: 13px 23px;
}
  .mszBox {
    bottom: 83px;
}
  .myProfileInner a.dropdown-item {
    padding: 0;
}

  .myProfile {
    align-items: start!important;
}
  .contentCommon {
    padding: 21px 13px!important;
}

  .innerCourseCart_ {
    height: calc(100vh - 486px)!important;
}

  .showpage_ {
    justify-content: center;
    margin-top: 16px;
}
.myProfile .updateBtn {
  justify-content: start;
  padding: 12px 0;
}
  .myProfile {
    flex-direction: column;
    padding-left: 10px;
}
  .filterCategory .swiper-slide {
    max-width: 120px !important;
}

  .filterCategory .swiper-slide-active {
    max-width: initial !important;
    margin-right: 0;
    width: auto!important;
}
.filterCategory  .tab {

  padding: 11px 17px 11px 5px;
  max-width: initial;
  width: auto;
}

  .inputOuter input {
 
    min-width: auto!important;
}
  .courseCardConRecipe .courseDrpodown {
    transform: translate(4px, -32px) !important;
  }
  .ratinglike_Options {
    right: 14px;
}
  .vedio {
    height: 349px !important;
}
  .courseDurationDetials li:nth-child(1) {
    min-width: 69px !important;
  }
  .recipe-contols-outer .recipeBtnCircle {
    padding: 9px 17px!important;
  }
  .customSelect select {
    padding: 9px 55px 9px 15px !important;
    font-size: 14px!important;
  }
  .serachBar input {
    font-size: 13px !important;
  }
  .searchIcon {
    top: 11px !important;
    left: 20px !important;
    width: 17px !important;
  }
  .comment-content {
    width: 98%;
  
}
  .comment-msg {
    width: 83%;
    
}
  .ourCustomers:after {
    width: 343px;
    height: 224px;
    top: -95px;
}
  
  .subscripinner_ .col-md-12.text-center.pt-5 {
    padding-top: 3px!important;
}
.sliderImgOuter img {
    max-height: 266px;
}
  .sliderBoxInner {
    padding: 34px 18px;
}
  .sliderBoxInner .button {
    padding: 7px 12px;
    font-size: 12px;
  }
  .sliderBoxInner .button {
    margin-bottom: 8px;
}
  .btnaction_ {
    justify-content: center;
}
  .otpBox {
    display: flex;
    justify-content: center;
}
  .detailrecipe .bigHeading {
    font-size: 21px;
    line-height: 27px;
}
  .detailrecipe .pt-5.text.mt-4 {
    padding-top: 10px!important;
}
  .Nutrition_cards {
    word-break: break-all;
}
  .ownerdetail_Lynnette {
    padding: 148px 0px 0px 0px;
}
  .BannerContent h2 {
    font-size: 23px;
}
.BannerContent > div p {
  font-size: 14px;
}
  .downloadApps-bg {
    padding: 40px 15px 80px 15px;
}
  #staffsTable_length .subtext {
    font-size: 12px !important;
  }
  .paginationBox li a {
    margin-right: 18px!important;
  }
  .paginationBox .selected {
    margin-right: 3px!important;
  }
  .perfectbreakfast .cookingOut .cookingOuter .subtext {
    font-size: 13px!important;
    line-height: 22px!important;
}
  .cookingOuter {
    margin-left: 0!important;
    margin-bottom: 10px;
}
  .ourRecipe.RecipesPage .tabnav li {
    width: 46%;
    margin-right: 10px;
}
  .classdetailbg {
    margin-top: -72px;
}

  .recipeBanner.favoritesBanner .bigHeading {
    font-size: 23px;
    line-height: 33px;
}
  .downloadAppsOuter {
    min-height: 672px;
}
  .ourRecipe .tabnavouter li {
    width: 100%;
}
.ourRecipe .tabnavouter .tab {
  max-width: 359px;
}
  .DefaultPara {
    font-size: 15px !important;
}
  .recipeBanner.favoritesBanner .text {
    margin: 27px 0 0!important;
}
.recipeBanner.favoritesBanner .bigHeading {
  padding-top: 0!important;
}
  .backBtn button {
    font-size: 12px;
  }

  .ratingButton {
    padding: 6px 1rem;
    font-size: 12px;
  }

  .commentsHeading {
    font-size: 15px;
    line-height: normal;
  }

  .profile_outer img {
    width: 35px;
    height: 35px;
  }

  .commentCount {
    font-size: 8px;
  }

  .buttonStar {
    width: 20px;
    height: 20px;
  }

  .ratingValue {
    font-size: 13px;
  }

  #goLiveModal .goLiveModalFoot {
    flex-wrap: wrap;
  }

  .otherComments {
    padding: 14px 12px;
    margin-top: 0rem;
  }

  .commentUser {
    font-size: 12px;
  }

  .commentuserTimeing {
    font-size: 9px;
  }

  .otherCommentsreply {
    margin-top: 1rem;
  }

  .userComment {
    font-size: 10px;
  }

  .userMsg.flexBox {
    width: 100%;
  }

  .change-password-key {
    display: none;
  }

  .inputOuter {
    padding: 10px 0px !important;
  }

  .loginForm .form-control {
    padding: 11.5px 45px 11.5px 47px;
  }

  .setting-main-outer .text {
    font-size: 10px !important;
    line-height: 15px;
  }

  .toptextOuter p {
    font-size: 14px;
  }

  .toptextOuter {
    padding: 10px 0 0;
}

  .cancle-update-profile {
    display: inline;
  }

  .primaryBtn {
    width: 100% !important;
    max-width: 100% !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    margin-bottom: 11px !important;
  }

  .profileForm .form-control {
    padding: 11px 11px 11px 47px;
  }

  .cart_max_child {
    padding: 0 10px;
  }

  .rightArrow .eye {
    height: 30px;
    width: 30px;
  }

  .IngredientsList li {
    padding: 4px 6px !important;
  }
  .classtabsOuter .IngredientsList li {
    padding: 4px 32px !important;
}
  .courseHeading {
    font-size: 18px;
  }

  .lernBtn {
    font-size: 10px !important;
  }

  .IngredientsList li a {
    font-size: 11px !important;
  }

  .profileForm .form-label {
    font-size: 12px !important;
  }

  .AddClass .profileForm .form-control {
    padding: 13px 13px 13px 13px !important;
  }

  .AddClass .profileForm {
    padding: 13px !important;
  }

  .recipeImgOuter-resp {
    flex-direction: column;
    justify-content: space-evenly;
    gap: 11px;
  }

  .labelNewCase {
    padding: 10px !important;
  }

  .file_span {
    font-size: 12px !important;
  }

  .serachBar input {
    max-width: 100% !important;
  }

  .recipeImgOuter {
    flex-direction: column;
    gap: 10px;
  }

  .serachBar {
    width: 100%;
  }

  .commanScrollbar {
    overflow: auto;
    height: 345px;
  }

  .chatUserList {
    height: auto;
  }

  .stepInnerrr {
    gap: 2px;
    align-items: center;
  }

  .InputOuter-outer input.form-control {
    padding: 12px 7px 12px 7px !important;
    font-size: 13px;
  }

  .IngredientsBoxInner {
    flex-direction: column;
    gap: 12px;
  }

  .editDeleteBtn .editBtn {
    padding: 8px 19px 8px 24px !important;
    font-size: 9px !important;
  }

  .courseCardCon {
    padding-right: 2px !important;
    overflow-y: hidden !important;
  }
  .contentCommon.insidecontent_ .courseCardCon {
    overflow-y: auto !important;
}
  .commonCourse {
    margin-bottom: 12px;
  }

  .detail-recipe-outer {
    display: inline;
  }

  .details-heading-recipe {
    margin-bottom: 11px;
  }

  .courseHeading {
    word-wrap: break-word;
  }

  .add-classess-btn .login-btn {
    width: 100%;
  }

  .add-classess-btn {
    width: 100%;
    margin-top: 13px;
  }

  .add-classess-btn a {
    display: flex;
    align-items: center;
  }

  .class-content-resp .searchDataTable {
    display: inline;
  }

  .class-content-resp .search.subtext {
    max-width: 100%;
  }

  .class-content-resp .customSelect {
    width: 100%;
  }

  .courseCard {
    display: block !important;
  }

  .courseImg {
    margin: 0 !important;
  }

  .courseImg img {
    width: 100% !important;
  }

  .space {
    padding: 20px 0px 40px !important;
  }

  .courseDurationDetials li {
    min-width: 117px !important;
    margin-right: 8px !important;
  }

  .courseDurationDetials li p {
    font-size: 12px !important;
  }

  .courseDurationDetials li h6 {
    font-size: 12px !important;
  }

  .contentCommon .paginationBox {
    bottom: 19px !important;
    right: 24px !important;
  }

  .rightArrow {
    margin-bottom: 20px;
  }

  .innersearchbar.inner-controls {
    margin-top: 10px;
    flex-wrap: wrap;
    gap: 7px;
  }

  .recipe-contols-outer .text {
    font-size: 13px !important;
    width: 100%;
    line-height: 12px;
  }

  .recipe-contols-outer .dataTables_length {
    display: none !important;
  }

  .inner-controls .add-recipe-btn {
    width: 100%;
  }

  .recipe-contols-outer .serachBar {
    width: 100%;
  }

  .inner-controls .customSelect {
    width: 100%;
    flex-direction: column;
    margin-top: 10px;
  }

  .recipe-contols-outer .serachBar input {
    max-width: 100%;
  }

  .inner-controls .login-btn {
    width: 100%;
    padding: 13px 10px;
    font-size: 13px;
  }

  .recipe-contols-outer .recipeBtnCircle {
    padding: 9px 14px;
  }

  .recipe-contols-outer .searchDataTable {
    display: block;
  }

  .slideOnebtn {
    right: 100px;
    max-width: 92px;
  }

  .customSelect select {
    width: 100%;
    font-size: 12px;
  }

  .profile-dropdown-btn {
    display: none;
  }

  .admin-profile-dropdown .user_img {
    margin-right: 0;
  }

  .mszBoxTop {
    top: 25px;
    max-width: 50px;
    right: 11px;
  }

  #goLiveModal {
    position: absolute;
    right: 0;
    margin-top: 0;
    width: unset;
    max-width: unset !important;
  }

  .live-end-camera {
    left: 36%;
  }

  .adminvedioInner .button {
    padding: 9px 6px;
    font-weight: 600;
    font-size: 12px;
  }

  .profileForm .form-control {
    padding: 10px 19px 11px 14px;
  }

  .live-modal-title {
    font-size: 14px;
    width: 98%;
  }

  .circle-btnResp {
    padding: 12px 18px !important;
  }

  .cookingOuter {
    width: 100%;
  }
  .perfectbreakfast .instrutOut .cookingOuter {
    margin-left: 0!important;
}
.perfectbreakfast .instrutOut .cookingOuter .subtext {
  font-size: 13px !important;
}
  .InstructorOuter {
    padding-right: 0;
  }

  .perfectbreakfast .instrutOut .cookingOut {
    width: 100%;
    gap: 8px;
}
.inrecipeout {
	width: 100% !important;
}
.subtext {
  font-size: 12px !important;
  line-height: normal !important;
}
  .foodboxInner .subtext {
    font-size: 12px !important;
}
.shapebgInner .subtext {
  font-size: 12px!important;
  line-height: 19px!important;
}
  .perfectbreakfast .instrutOut {
    align-items: start!important;
}

.downloadAppsOuter {
  min-height: 782px!important;
}

.favoritesBanner {
  height: 257px;
  padding: 27px 0px;
}

  .appBtn {
    padding: 14px 22px;
  }

  .banner-section .swiper-button-next {
    top: 94%;
    right: 0;
}

  .banner-section .swiper-button-prev {
    left: 84%;
    top: 94%;
}

.swiperClassResp .tabIcons {
  width: 30px;
  height: 30px;
  padding: 4px;
}

  .sliderOuterScrollbar .swiper-scrollbar.swiper-scrollbar-horizontal {
    width: calc(60% - 2 * var(--swiper-scrollbar-sides-offset, 1%));
  }

  .swiperClassResp .tab {
    height: 40px;
    margin-bottom: 25px;
  }

  .swiperClassResp .swiper-button-next {
    height: 35px;
    width: 35px;
  }

  .swiperClassResp .swiper-button-prev {
    height: 35px;
    width: 35px;
  }

  .formTabs {
    display: block;
    padding: 0px 0px;
  }

  .loginBtnOuter {
    flex-direction: column;
    align-items: center;
  }
  .userimgfeild {
    color: #8a7c8d;
    font-size: 8px;
}
  .text {
    font-size: 12px !important;
  }

  .ourCustomers:before {
    width: 185px;
    height: 185px;
    top: -70px;
  }

 .notifyOuter {
    max-height: 655px !important;
}

.heightouter .notifyOuter {
  max-height: 517px!important;
}
  .detailRecipeCardInner {
    gap: 10px;
  }

  .bigHeading {
    padding: 10px !important;
  }

  .commented-stars span {
    font-size: 14px !important;
  }

  .classdetailText .DefaultPara {
    font-size: 10px !important;
    line-height: normal !important;
  }

  .reply_button {
    padding: 6px 16px !important;
  }

  .recommendOthers {
    flex-direction: column;
  }

  .tradingRecipe .swiper-button-prev {
    left: 70%;
  }

  .vedio {
    height: auto;
  }
  .login_create .sendButton {
    font-size: 15px;
}
  .cookingOut {
    flex-direction: column;
  }

  .Slide {
    justify-content: center;
  }

  .Aboutusrecommendrecipe {
    flex-direction: column;
  }

  .likeDislike {
    padding-left: 0px;
  }

  .ratinglike_Options {
    display: flex;
    align-items: center;
  }

  .AboutUs_likeComments {
    flex-direction: row;
    width: 155px;
    justify-content: space-around;
    margin-bottom: 0;
    margin-right: 20px;
  }

  .Aboutus_heartimg {
    width: 60px;
    height: 60px;
  }

  .AboutUs_likecomment_show {
    padding: 5px;
  }

  .AboutUs_Startrating {
    width: 60px;
  }

  .likeDislikeOuter {
    justify-content: space-between;
  }

  .shapeBg {
    width: 354px;
    padding: 20px 25px;
}

  .iconBox {
    width: 70px;
    height: 70px;
    bottom: -20px;
  }

  .searchCirle {
    padding: 13px 16px !important;
  }

  .searchCirle img {
    width: 15px !important;
  }

  .streamsLive {
    padding-top: 20px;
  }

  .iconBox img {
    width: 20px;
  }

  .subheading {
    font-size: 20px;
    line-height: 30px;
  }

  .verticalImg {
    top: 167px;
    right: -7px;
}

.streamimg {
  width: 240px;
  height: 350px;
}
  .iconBox {
    left: 55px;
  }

  .appBtnOuter {
    padding-top: 0 !important;
  }

  .appIcon {
    padding-right: 8px;
  }

  .whyChooseus {
    padding: 38px 0 0px;
}

  .logininner {
    padding: 15px 10px;
  }

  .editProfileBtn {
    right: 25px !important;
  }

  .adminProfileForm {
    padding-top: 20px;
  }

  .notifyOuter {
    padding: 0px 0px !important;
}

  .accountText {
    text-align: center;
    padding-top: 20px;
  }

  .planBoxInner {
    padding: 30px 10px 40px !important;
  }

  .circle-btn {
    padding: 4px 10px;
  }

  .circle-btn img {
    width: 10px;
  }

  .copyrightText {
    padding-top: 0px !important;
    position: relative;
    text-align: center;
    font-size: 12px!important;
}
.resendText {
  font-size: 15px!important;
}
  .logininner {
    text-align: center;
  }

  .logininner a {
    justify-content: center;
  }

  .footerLink {
    width: 100%;
    padding-bottom: 30px;
  }

  .seeFullrecipe {
    font-size: 10px;
  }

  .foodBox {
    padding: 0;
  }

  .footerListInner {
    padding-bottom: 0px;
  }

  .footerInput input {
    padding: 14px 15px;
    max-width: 200px;
    width: 100%;
  }

  .footercircle img {
    width: 15px;
  }

  .footercircle {
    padding: 6px 10px !important;
  }

  .leftContent {
    max-width: 200px;
    width: 100%;
  }

  /* frontend_R */
  .dashboardStream.graphconText {
    font-size: 10px !important;
  }

  .digitBox {
    font-size: 11px !important;
  }

  /* frontend_R */
}
@media (max-width: 445px) {
.sliderImgOuter img {
  width: 423px!important;
}
}
@media (max-width: 425px) {
  .reportsModal .custom-file__label {
    min-width: 195px;
  }

  .downloadAppsOuter {
    min-height: 560px;
  }

  .appBtn {
    padding: 8px 19px;
  }

  .extraSmallText {
    font-size: 6px;
  }

  .streamContent {
    margin-top: 245px;
  }

  .commentContent .DefaultPara {
    font-size: 12px !important;
  }

  .swiperClassResp .tabIcons {
    width: 26px;
    height: 26px;
  }

  .swiperClass {
    width: 65% !important;
  }
}

@media (max-width: 650px) {
  .downloadAppsOuter {
    min-height: 956px;
}
}

@media (max-width: 475px) {
  .sliderImgOuter img {
    width: 439px;
}
  .smtext_ {
    font-size: 14px !important;
  }

  .appBtn {
    padding: 16px 29px;
  }


}

@media (max-width: 425px) {
  .appIcon img {
    width: 18px;
  }

  .appBtn {
    padding: 8px 10px;
  }

  .extraSmallText {
    font-size: 5px;
  }

  /*  Frontend_R */
  .contentCommon th {
    max-width: 16ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

  }

  .contentCommon td {
    max-width: 16ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .digitBox {
    font-size: 11px;
  }

  /*  Frontend_R */
  .contentCommon table td {
    max-width: 16ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


}
@media (max-width: 400px) {
  #notificationAdmin {
    width: 339px;
}
.heightouter .notifyOuter {
  max-height: 482px!important;
}

.editProfileBtn {
  right: 5px !important;
}
.sliderImgOuter img {
  width: 369px!important;
}
}

@media (max-width: 380px) {
.navbar-brand img {
  width: 75px;
}
.recipeBanner.recipeBannerRes .text {
  font-size: 12px!important;
}
.recipeBanner.recipeBannerRes .bigHeadingResp {
  font-size: 24px!important;
}
.positionpaltes_ {
  bottom: -81px;
  width: 89%;
}
.recipeBanner {
  height: 270px!important;
}
.contactus_leftTouchin {
  min-width: 337px;
}
.contactUs_mapImg {
  width: 100%;
}

}
@media only screen and (min-width: 992px) and (max-width: 1200px) {

  .downloadApps-bg {
    background-size: 94% 87%;
    top: 204px;
  }

}

@media only screen and (min-width: 1200px) and (max-width: 1236px) {
  .downloadApps-bg {
    background-size: 95% 94%;
    top: 146px;
  }
}

@media only screen and (min-width: 1236px) and (max-width: 1296px) {
  .downloadApps-bg {
    background-size: 95% 100%;
    top: 154px;
  }
}

@media (max-width: 375px) {
  .submitBtn {
    padding: 17px 18px!important;
}
  .primaryBtn {
    padding: 17px 21px !important;
}
  .heartCircle img {
    width: 13px;
  }

  .navBarText {
    font-size: 13px !important;

  }

  .adminvedioInner .button {
    padding: 9px 4px;
    font-weight: 500;
    font-size: 10px;
  }

  .top-head p {
    font-size: 6px !important;
  }


  .streamContent {
    margin-top: 289px;
  }

  .sliderImgOuter img {
    width: 349px;
    min-height: 273px;
    height: 100%;
  }

  .slideOnebtn {
    right: 54px;
    max-width: 92px;
  }

  .top-head h2 {
    padding-top: 25px !important;
    font-size: 15px;
  }

  .recipeBanner {
    height: 315px;
  }

  .heartCircle {
    padding: 1px 6px;
    margin-left: 1px;
  }

  .editProfile {
    padding: 0px 10px !important;
  }

  .sliderBoxInner {
    padding: 18px 14px;
  }

  .sliderBoxInner+div {
    padding-bottom: 1rem !important;
  }

 
}
@media (max-width: 360px) {
.sliderImgOuter img {
  width: 336px!important;
}
}
@media (max-width: 350px) {
#notificationAdmin {
  width: 304px;
}
}