
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8a7c8d;
}
.adminLoginOuter:before {
  display: none !important;
}

.primaryBtn {
  text-align: center;
  border: none;
  font-weight: 700;
  border-radius: 30px;
  max-width: 180px;
  display: flex;
  width: 142px;
  height: 55px;
  padding: 17px 27px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.text_ {
  font-size: 18px;
  color: #8a7c8d;
}

.innereditxt {
  font-size: 15px;
}

.innertxt {
  font-size: 15px;
}

.ElementsApp .InputElement.is-invalid {
  color: orange !important;
}

/*sidenavbar style*/
.toggle-button {
  display: none;
}

.SideNavInner {
  padding: 30px 50px;
}

.mainAdmin {
  display: flex;
  background: #ffffff;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 0px);
}

.sidebar {
  max-width: 240px;
  background: #ffffff;
  border-radius: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  display: inline-block;
}

.commonBody {
  background: #ffffff;
  min-width: calc(100% - 240px);
  margin-left: 240px;
  height: 100vh;
  width: 100%;
  padding: 25px 25px 0;
}

.commonBody.streamLiveBody {
  margin-left: 0;
}

.sidebarLogo {
  margin: 20px 0 40px 30px;
}

.sidebarCancel {
  color: rgba(233, 165, 243, 1);
  font-size: 20px;
}

.crossBtn {
  position: relative;
}

.nav_link {
  margin-bottom: 30px;
  padding: 0 30px;
}

.nav_link img {
  margin-right: 10px;
  position: relative;
  bottom: 3px;
  padding: 10px;
}

.nav_link.active img {
  bottom: 0;
}

.nav_link.active img {
  padding: 10px;
  background: #e9a5f3;
  box-shadow: 0px 3px 5px 0px #e9a5f380;
  border-radius: 12px;
  margin-right: 10px;
}

.nav_link.active {
  color: #e9a5f3;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.nav_link.active a {
  display: flex;
  align-items: center;
}

.nav_link.active .hide {
  display: block;
}

.nav_link.active .sidebar_icon {
  display: none;
}

.planIcon {
  max-width: 39px;
}

.nav_link.active .planIconHide {
  padding: 0px;
  max-width: 39px;
}

.nav_link .hide {
  display: none;
}

.sidebar_menu .nav_link.active a {
  color: #e9a5f3;
  display: flex;
  align-items: center;
}

.nav_link a {
  font-weight: 400;
  font-size: 16px;
  color: #b9b0bb;
}

.nav_main {
  padding: 0;
}

.sidebarInner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  /* padding: 0px 0px 40px; */
}

/* Start Header Css */
.Topnavbar {
  margin: 0 0 30px;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar_box {
  display: flex;
  align-items: center;
}

.notification_box {
  background: #0fb7ea;
  border-radius: 12px;
  padding: 10px;
  position: relative;
}

.notification_box:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: #c90000;
  border-radius: 50px;
  border: 2px solid #c90000;
  right: -4px;
  top: -4px;
}

.headerRight {
  display: flex;
  align-items: center;
}

.headerRight .dropdown {
  min-width: auto;
  background: #ffffff;
  border-radius: 28px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  box-shadow: 0px 5px 25px 0px #e0a6e93b;
}

.headerRight .downArrow {
  background-color: #e9a5f347;
  width: 15px;
  height: 15px;
  padding: 8px 14px;
  margin-left: 45px;
}

/* .dropdown-menu {
  min-width: 100%;
} */
.headerRight button {
  padding: 0;
  border: none;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: #2d2832;
}

.headerRight button::after {
  display: none;
}

.headerRight small {
  display: flex;
  font-size: 14px;
  color: #91979d;
  font-weight: 400;
}

.user_img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.headerRight .dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  margin-top: 5px;
}

.headerRight .dropdown-menu.show {
  visibility: visible;
  display: flex;
  flex-direction: column;
}

.headerRight .dropdown button:focus {
  box-shadow: none;
}

.navbar_box .favouriteIcon {
  box-shadow: 0px 5px 25px 0px #e0a6e93b;
}

.downArrow {
  padding: 0px 7px;
  background: #fdf7fe;
  border-radius: 100%;
  position: relative;
  top: 11px;
  margin-left: 40px;
  cursor: pointer;
}

/* End Header Css */
.dashCard {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 10px 25px rgb(0 0 0 / 5%);
  border-radius: 15px;
  padding: 10px 15px;
  position: relative;
}

.dashCardImg {
  background: #e9a5f3;
  display: inline-block;
  box-shadow: 0px 5px 10px 0px #e9a5f359;
  border-radius: 15px;
  margin-right: 16px;
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47px;
  height: 47px;
}

.keycardImg {
  background: #e9a5f3;
  display: inline-block;
  box-shadow: 0px 5px 10px 0px #e9a5f359;
  border-radius: 15px;
  margin-right: 16px;
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RotateArrowpink {
  transform: rotate(45deg);
  position: absolute;
  right: 25px;
  top: 15px;
}

.dashCardHeading h2 {
  font-size: 16px;
  color: #8a7c8d;
  font-weight: 400;
  margin-bottom: 3px;
}

.cardata {
  color: #2d2832;
  font-size: 14px;
  font-weight: 500;
}

.contentCommon {
  background: rgb(234 167 239 / 10%);
  border-radius: 30px;
  padding: 31px 25px;
  height: 85vh;
  overflow-y: auto;
}

.insidecontent_ {
  height: calc(100vh - 126px);
}

.privacyPolicy {
  height: calc(100% - 235px);
}

.graphCon {
  background: #ffffff;
  border-radius: 15px;
  padding: 16px;
  position: relative;
  height: calc(100% - 45px);
  overflow: auto;
}

.graphCon::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.graphCon::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Handle */
.graphCon::-webkit-scrollbar-thumb {
  background: #8a7c8d;
}

.graphconText {
  color: #4c4747;
  font-size: 16px;
  font-weight: 500;
}

.custom_modal .modal-dialog {
  transform: none;
  position: absolute;
  right: 18px;
  top: 12px;
  margin-top: 4px;
  width: 100%;
  z-index: 4;
  max-width: 400px;
  min-height: calc(100% - 1rem);
}

.custom_modal .modal-content {
  background-color: #ffffff;
  border: none !important;
  border-radius: 20px;
}

.custom_modal .modal-header {
  border: none;
  color: #4c4747;
  padding: 12px 24px 6px;
  border-radius: 20px 20px 0px 0px;
  border-bottom: 1px solid #f5f5f5;
}

.custom_modal .btn_close {
  background: transparent;
  border: none;
  color: #b7b7b7;
  font-size: 33px;
}

.custom_modal .dashCard {
  background: rgba(245, 245, 245, 0.5);
  align-items: start;
  box-shadow: none;
}

.custom_modal .dashCardImg {
  padding: 9px 12px;
}

.DcardImg {
  padding: 12px 8px;
}

.custom_modal .modal-body {
  padding: 24px;
}

.custom_modal .btn-outline-info {
  background: #ffffff;
  border: 1px solid #0fb7ea;
  box-shadow: 0px 0px 10px rgb(15 183 234 / 15%);
  border-radius: 7px !important;
  font-size: 13px;
}

.custom_modal .notificationStatus {
  opacity: 0.8;
  font-size: 10px;
  color: #b7b7b7;
  font-weight: 400;
}

.notiCon {
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.custom_modal .modal-dialog-scrollable .modal-content {
  height: 98%;
}

.contentCommon table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.tableBorder_ th {
  border-bottom: 2px solid #f5f3f5 !important;
}

.contentCommon td,
th {
  text-align: left;
  padding: 12px 8px;
  font-family: "Poppins";
}

.contentCommon th {
  color: #2d2832 !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

.contentCommon td {
  color: #8a7c8d !important;
  font-weight: 500 !important;
  font-size: 13px !important;
}

/* .contentCommon tr:first-child {
  border-bottom: 2px solid #f5f3f5;
} */
.contentCommon tr {
  border-bottom: 1px solid #f5f3f5;
}

.contentCommon tr:nth-child(2) td {
  padding-top: 20px;
}

.digitBox {
  padding: 0px 8px;
  background: #e9a5f3;
  border-radius: 15px;
  box-shadow: 0px 5px 10px 0px #e9a5f359;
  font-size: 13px;
  color: #fff;
  margin-left: 8px;
}

.rightScrollBox {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 5px 15px 0px #e9a5f326;
  overflow: auto;
  max-height: 431px;
}

/* width */
.rightScrollBox::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.rightScrollBox::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.rightScrollBox::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.rightScrollBox ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.rightScrollInner {
  background-color: #fcf3fd;
  padding: 12px;
  border-radius: 18px;
  margin-bottom: 15px;
}

.ScrollBoxContent {
  display: flex;
  /* justify-content: space-between; */
}

.ScrollBoxImg {
  max-width: 99px;
}

.ScrollBoxImg img {
  border-radius: 18px;
  height: 99px;
  width: 99px;
  object-fit: cover;
}

.infoCirle {
  width: 34px;
  height: 34px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.AboutBtn {
  width: 100%;
}

.AboutBtn button {
  width: 100%;
  background-color: #fff;
  border: none;
  padding: 10px;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: 600;
}

/* End Dashboard Css */
/* Start My Profile  */
.myProfile {
  display: flex;
  align-items: center;
}

.spaceInner {
  height: calc(100% - 95px);
  overflow: auto;
  border-radius: 15px;
}

.insideSpace_ {
  height: calc(100% - 117px) !important;
}

/* width */
.spaceInner::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.spaceInner::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.spaceInner::-webkit-scrollbar-thumb {
  background: #8a7c8d;
}

.space {
  background-color: #fff;
  padding: 20px 30px 40px;
  border-radius: 15px;
  box-shadow: 0px 5px 15px 0px #e9a5f326;
  width: 99%;
  overflow: auto;
}

.bgremove_ {
  background-color: transparent;
}

/* width */
.space::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.space::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.space::-webkit-scrollbar-thumb {
  background: #8a7c8d;
}

.myProfile .profileBig {
  width: 110px;
  height: 110px;
  border-radius: 95px;
  margin: 0 32px 0 0;
}

.myProfileInner {
  display: inline-block;
  width: 100%;
  position: relative;
}

.myProfileInner button:focus {
  box-shadow: none;
}

.myProfileInner button:hover {
  color: #e9a5f3;
}

.myProfileInner h5 {
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 13px;
}

.updateBtn {
  color: #e9a5f3;
  display: block;
  border: none !important;
  display: flex;
  justify-content: end;
  width: 100%;
}

.updateBtn:active {
  border: none;
}

.profileTable {
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 13px;
  margin-top: 15px;
}

.profileTable tr:first-child {
  border-bottom: none;
}

.profileTable tr:nth-child(2) td {
  padding-top: 0px;
}

.profileTable .details {
  font-weight: 400;
  font-size: 14px;
  color: #91979d;
}

.profileTable .data {
  font-weight: 500;
  font-size: 14px;
  color: #4c4747;
}

.profileTable .data td {
  padding-bottom: 13px;
}

.profileTable td {
  font-size: 14px;
  color: #2d2832;
  font-family: "Poppins";
}

.profileTable .details td {
  font-size: 14px;
  color: #8a7c8d;
  font-family: "Poppins";
}

.profileTable .data th {
  padding-bottom: 13px;
}

/* Start Update Profile Modal */
.profileStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.userStatus {
  display: flex;
  justify-content: space-between;
}

.custom_modal.myProfileModal .dashCard {
  background: none;
  box-shadow: none;
  align-items: center;
}

.myProfileModal .dashCardImg {
  background: rgb(15 183 234 / 10%);
  box-shadow: none;
  border-radius: 10px;
  padding: 13px 9px;
}

.myProfileModal .dashCardHeading p {
  font-size: 14px;
  font-weight: 400;
}

/* End Update Profile Modal */
/* Start Edit My Profile */
.cart_img {
  position: relative;
  display: inline-block;
}

.updateProfileDropdown {
  border-radius: 10px !important;
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 2px 22px);
  border: none !important;
  transform: translate(73%, 66%) !important;
  margin-top: -50px !important;
}

.updateprofileDropdown {
  width: 250px;
  padding: 20px;
}

.updateProfileDropdown .dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent !important;
  cursor: pointer;
}

.updateProfileDropdown .dropdown-item {
  color: #b9b0bb;
  font-size: 15px;
}

.updateProfileDropdown li {
  padding: 8px 0px;
}

.profile_edit {
  position: absolute;
  bottom: 0px;
  right: 0px;
  cursor: pointer;
  height: 35px;
}

.profileForm .form-group {
  text-align: left;
  position: relative;
}

#attachpdf .form-control {
  background: #fcf3fd;
  border-radius: 50px;
  border: none !important;
  padding: 17px 25px 17px 50px;
}

.profileForm .form-control {
  background: #fcf3fd;
  border-radius: 20px;
  border: none !important;
  padding: 17px 25px 17px 50px;
}

.inputIcon {
  position: absolute;
  left: 25px;
}

.form-control:focus {
  box-shadow: none;
}

.profileForm .form-label {
  font-size: 14px;
  color: #4c4747;
  font-weight: 500;
}

.profileBig {
  width: 110px;
  height: 110px;
  border-radius: 95px;
  margin: 0 32px 0 0;
  object-fit: cover;
  border: 1px solid #eaa7efbf;
}

.addProfileImg {
  border-radius: 10px;
  width: 75px;
  height: 75px;
}

.inactiveBtn span {
  color: #f36767;
}

.cancelBtn {
  background: rgba(185 176 187 / 10%);
  color: #8a7c8d;
  font-size: 14px;
  text-transform: uppercase;
}
.submitBtn {
  background: #e9a5f3;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 17px 18px;
}

.custom_modal .modal-footer {
  padding: 20px 37px;
  justify-content: space-between !important;
}

/* End Edit My Profile */
/* Start User  */
.serachBar {
  position: relative;
}

.searchIcon {
  position: absolute;
  z-index: 0;
  top: 10px;
  padding-left: 0px;
  left: 10px;
  width: 21px;
}

.serachBar input {
  padding: 10px 21px 10px 48px;
  background: #ffffff;
  border-radius: 26px;
  border: none;
  max-width: 295px;
  width: 100%;
  box-shadow: 0px 5px 15px 0px #e9a5f326;
}

.dataTables_length label {
  color: #748383;
}

.dataTables_length .custom-select {
  padding: 5px 18px 5px 12px;
  background-image: url("../images/UpDown.png");
  background-repeat: no-repeat;
  background-position: 85%;
  width: auto;
  display: inline-block;
  border: none;
  border-radius: 19px;
  font-size: 12px;
  color: #4c4747;
}

.searchDataTable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  padding-bottom: 15px;
  flex-wrap: wrap;
}

.userTable th {
  font-weight: 500;
  font-size: 14px;
  color: #4c4747;
}

.userTable td {
  font-weight: 400;
  font-size: 14px;
  color: #91979d;
}

.activeStatus {
  display: inline-block;
  padding: 3px 15px;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #50d06d;
  background: rgb(80 208 109 / 0.07);
  border-radius: 5px;
}

.in.activeStatus {
  background: #f0f0f0;
  color: #91979d;
}

.eye,
.delete {
  cursor: pointer;
}

/* End User */
/*modal start*/
#detail .modal-content {
  border-radius: 25px;
  padding: 10px;
  height: 700px;
  max-width: 395px;
}

.modal-content {
  border-radius: 20px;
}

.modal-dialog {
  max-width: 600px !important;
}

.modal-header {
  justify-content: center;
  border-bottom: none;
}

.modal-header .btn-close {
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal-header .btn-close:focus {
  box-shadow: none;
}

#detail .modal-header {
  border-bottom: none;
}

#detail .modalHeader_ {
  display: none;
}

#detail {
  margin-right: 30px;
  margin-top: 0;
  display: flex;
  justify-content: end;
}

#detail .dashCardImg {
  background-color: rgb(233 165 243 / 7%);
  box-shadow: none;
}
.profileStatus .in.activeStatus {
  background: #f0f0f0;
  color: #f36767;
}
#detail .userStatus {
  border-bottom: 1px solid rgba(233, 165, 243, 0.15);
  border-top: 1px solid rgba(233, 165, 243, 0.15);
  width: 100%;
  background: transparent;
  justify-content: flex-start;
  height: auto;
}

#detail .dashCard {
  box-shadow: none;
  margin-right: 30px;
}

#detail .modal-footer {
  border-top: 0;
  position: absolute;
  bottom: 0;
  width: 85%;
}

#delete .modal-header {
  border-bottom: none;
  padding: 5px;
}

#delete .modal-content {
  border-radius: 25px;
  padding: 10px;
}

#delete {
  max-width: 585px;
}

#delete .modal-body {
  padding: 0;
}

.profileCircle {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  background-color: #e196ec;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 20px 10px #e9a5f380;
}

/* Start recipe Page Css */
.courseCard {
  background: #ffffff;
  border-radius: 15px;
  padding: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: 0px 5px 158px 0px #e9a5f326;
}

.courseCard .profileTable {
  border-bottom: none;
}

.courseCard .profileTable .data td {
  padding-bottom: none !important;
}

.courseImg {
  margin-right: 30px;
}

.courseImg img {
  width: 152px;
  border-radius: 7px;
  height: 152px;
  object-fit: cover;
}

.courseDetails {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.rightArrowOuter {
  max-width: 275px;
  width: 100%;
}

.rightArrow {
  display: flex;
  justify-content: end;
}

.courseDetailsInner {
  width: calc(100% - 75px);
}

/* .toptextOuter {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
} */

.textLiveStreaming {
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
  max-width: 300px;
  width: 100%;
  word-break: break-all;
}

.courseDurationDetials {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  align-items: self-start;
}

.title {
  width: 250px;
  margin-right: 0px !important;
}

.discription {
  width: calc(100% - 250px);
}

.amount {
  width: 150px;
}

.interval {
  width: 150px;
}

.courseDurationDetials li {
  margin-right: 30px;
}

.courseDurationDetials li:last-child {
  margin-right: 0px;
}

.courseDurationDetials li p {
  display: flex;
  font-size: 14px;
  color: #91979d;
  margin-bottom: 8px;
}

.innerCourseCart_ {
  height: calc(100vh - 324px);
}

.courseDurationDetials li h6 {
  color: #4c4747;
  font-size: 14px;
  font-weight: 500;
}

.toptextOuter p {
  max-width: 855px;
  word-break: break-all;
}

.backBtn button {
  box-shadow: none !important;
  outline: none !important;
}

.courseTab {
  display: flex;
  align-items: center;
  /* margin: 0 25px; */
  justify-content: space-between;
}

.courseTab .nav-link.active {
  border: 1px solid #0fb7ea !important;
  box-shadow: 0px 0px 10px rgb(15 183 234 / 15%) !important;
  border-radius: 12px !important;
  padding: 10px 20px !important;
  color: #0fb7ea !important;
}

.courseTab .nav-link {
  border: 1px solid #ffffff !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05) !important;
  border-radius: 12px !important;
  padding: 10px 20px !important;
  color: #959ba0 !important;
}

.courseBtn {
  padding: 9px 14px;
  font-size: 16px;
  display: flex;
  color: #0fb7ea;
  font-weight: 500;
  align-items: center;
  background: #ffffff;
  border: 1px dashed #0fb7ea;
  border-radius: 12px;
}

.courseCardCon {
  max-height: calc(100vh - 300px);
  overflow: auto;
  padding-right: 20px;
}

.courseCardConRecipe {
  overflow: unset;
}

.courseCardConRecipe {
  overflow: unset;
}

/* width */
.courseCardCon::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.courseCardCon::-webkit-scrollbar-track {
  background-color: rgb(233 165 243 / 15%);
}

/* Handle */
.courseCardCon::-webkit-scrollbar-thumb {
  background-color: #8a7c8d;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
}

.courseDrpodown {
  width: 200px !important;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 13px;
  transform: translate(-210px, -20px) !important;
}

.courseCardConRecipe .courseDrpodown {
  transform: translate(-80px, -20px) !important;
}

.courseCardConRecipe .PlanDropdown {
  transform: translate(-20px, -20px) !important;
}

.dots {
  cursor: pointer;
}

.customSelect select {
  padding: 6px 55px 6px 15px;
  font-size: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../images/downArrowdark.svg) 90% / 7% no-repeat #eee;
  box-shadow: 0px 5px 15px 0px #e9a5f326;
  border: none;
  background-color: #fff;
  outline: none;
  border-radius: 19px;
  color: #4c4747;
}

.playVedio {
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
}

.recipeText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  margin-top: 20px;
  color: #fff;
}

.pausedIcon {
  position: absolute;
  top: 50%;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vedioOuter {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 30px;
}

.vedioOuter .crossIcon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.videOter_ {
  background: #000;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}

/* End recipe Page Css */
/* Start Add recipe Css */

.AddClass .profileForm {
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 5px 158px 0px #e9a5f326;
  border-radius: 15px;
}

.AddClass .profileForm .form-control {
  padding: 17px 25px 17px 30px;
  resize: none;
}

.AddClass textarea.form-control {
  border-radius: 20px;
}

.disableClass.form-control {
  background-color: #d3cad4;
}

.optionContainer {
  display: flex !important;
  flex-direction: column;
  margin-top: 15px !important;
}

.multiSelectContainer li {
  padding: 10px;
  color: #e196ec;
}

.multiSelectContainer li:hover {
  background-color: #e196ec !important;
}

.highlightOption {
  background-color: #e196ec !important;
  color: #fff !important;
}

.searchWrapper {
  border: 2px solid #e9a5f3 !important;
  border-radius: 10px !important;
  padding: 9px !important;
}

.highlightOption {
  background-color: #e196ec !important;
}

.chip {
  background-color: #e196ec !important;
}

.optionListContainer {
  position: static !important;
}

.courseHeading {
  color: #4c4747;
}

.customLabel {
  font-weight: 500;
  font-size: 14px;
  color: #4c4747;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}

.labelNewCase {
  border-radius: 0;
  cursor: pointer;
  padding: 45px;
  width: 100%;
}

.upload_img {
  margin: 0 auto;
  display: flex;
  margin-bottom: 8px;
}

.file_span {
  display: block;
  text-align: center;
  font-size: 16px;
  color: #91979d;
  font-weight: 400;
}

.AddClass .profileForm .fileBorder {
  border: 2px dashed #f9c0ff !important;
  border-radius: 20px;
}

.dnldFileOuter {
  width: 92px;
  height: 92px;
  background-color: #fff;
  margin: 0 auto 12px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.courseFoot {
  display: flex;
  justify-content: space-between;
}

.courseFoot .primaryBtn {
  max-width: 130px;
  width: 100%;
}

.IngredientsBox {
  background-color: #fff;
  box-shadow: 0px 5px 25px 0px #e9a5f333;
  border-radius: 15px;
  margin-top: 40px;
  /* padding: 10px 0px 20px; */
}

.IngredientsBoxInner {
  padding: 25px 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-group-outer {
  padding-top: 0;
  padding-bottom: 10px;
}

.stepOuter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 45px 0px 20px;
}

.recipeBtnCircle {
  padding: 9px 14px;
}

.stepText {
  position: absolute;
  top: 3px;
  left: 10px;
  font-weight: 500;
}

.lernBtn {
  padding: 9px 30px;
  font-size: 16px;
  display: flex;
  color: #91979d;
  font-weight: 500;
  align-items: center;
  background: #ffffff;
  border: 1px dashed #e9a5f3;
  border-radius: 50px;
}

.attachPdfBtn {
  display: inline-block;
  color: #91979d !important;
}

/*radio button style start*/
.radioOuter {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  user-select: none;
  color: #8a7c8d;
}

/* Hide the browser's default radio button */
.radioOuter input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radioOuter .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid rgba(233, 165, 243, 30%);
  border-radius: 50%;
}

/* input:focus ~ .checkmark {
  box-shadow: 0px 0px 8px #E9A5F3;
  border-color: #E9A5F3;
} */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radioOuter input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioOuter .checkmark:after {
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #e9a5f3;
  transform: translate(-50%, -50%);
}

/*detail recipe style start*/

.detailRecipeCard {
  justify-content: space-between;
  align-items: center;
}

.detailRecipeCardInner {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.editDeleteBtn .editBtn {
  border-color: #e9a5f3;
  padding: 13px 30px 13px 30px;
  border-radius: 50px;
  color: #e9a5f3;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  text-transform: uppercase;
}

.editDeleteBtn .editBtn:focus {
  box-shadow: none;
}

.editDeleteBtn .editBtn:hover {
  background-color: #e9a5f3;
  color: #fff;
}

.dangerBtn {
  border-color: #c90000 !important;
  color: #c90000 !important;
}

.dangerBtn:hover {
  background-color: #c90000 !important;
  color: #fff !important;
}

.stepsOuter {
  padding-top: 30px;
  position: relative;
  z-index: 0;
  word-break: break-all;
}

.stepOuterInner {
  position: relative;
  display: flex;
  align-items: center;
}

.stepOuterInner:before {
  content: "";
  position: absolute;
  top: -15px;
  left: 97px;
  height: 100%;
  border: 1px dashed rgba(233, 165, 243, 0.2);
  z-index: -1;
  min-height: 50px;
  bottom: 0;
}

.recipeStepTextBox {
  margin-bottom: 15px;
}

.recipebgimg {
  /* max-width: 1200px; */
  position: relative;
}

.recipebgimg img {
  object-fit: cover;
  border-radius: 20px;
  /* height: 610px; */
  max-height: 610px;
}

.validationMsg {
  color: #b83434ed;
  font-size: 15px;
  font-weight: 400;
  line-height: 16px !important;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}

/*attact pdf modal style*/
.modalSearch input {
  box-shadow: 0px 5px 15px 0px #e9a5f326 !important;
  background-color: #fff !important;
  outline: 1px solid rgba(233 165 243 / 40%) !important;
}

.addRecipeBox {
  background-color: rgba(233 165 243 / 15%);
  box-shadow: 0px 5px 158px 0px #e9a5f326;
  border-radius: 13px;
  width: 100%;
  margin-bottom: 10px;
}

.addRecipeBoxInner {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.choosenBtn {
  display: inline-block;
}

.recipeImgOuter {
  display: flex;
  align-items: center;
}

.addClassCross {
  width: 30px;
  position: relative;
  bottom: 35px;
  right: 18px;
}

.recipeImg {
  max-width: 54px;
}

.modalSearch {
  padding: 1px 0px 40px;
}

.addRecipeBox .form-check .form-check-input {
  border-radius: 100%;
}
.attachPdf {
  max-width: 500px;
  margin: 0px auto 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  height: calc(100vh - 200px);
  overflow-y: auto;
  justify-content: center;
}

.InputOuter input {
  background-color: #fff !important;
  width: 100%;
}

.loader {
  max-width: 160px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader img {
  height: 100%;
}

.startStreamingBtn {
  position: absolute;
  top: 50px;
  right: 30px;
}

.previewVedio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.bgPreviewVideo {
  background-color: #000;
  border-radius: 20px;
}

/* .vedio {
    border-radius: 20px;
    height: 500px;
    object-fit: fill;
    width: 500px !important;
} */
.vedio {
  border-radius: 20px;
  height: 610px;
  object-fit: contain;
  background-color: #000;
}

.vedio_ {
  border-radius: 20px;
  height: 500px;
  object-fit: fill;
  width: 500px !important;
}

.previewVedio .crossIcon {
  width: 40px;
  position: absolute;
  top: 25px;
  right: 10px;
}

.ThumbnailImgOuter {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.ThumbnailImgOuter img {
  border-radius: 20px;
}

.ThumbnailImgOuter .crossIcon {
  position: absolute;
  top: 0;
  right: 0;
}

.userImg1 {
  width: 41px;
  height: 41px;
  border-radius: 100%;
  margin-right: 8px;
}

.userimgfeild {
  padding: 6px 25px 6px 5px !important;
}

.contentCommon .paginationBox {
  padding: 15px;
  position: absolute;
  right: 30px;
  bottom: 43px;
}

.paginationBox li a {
  color: #8a7c8d !important;
  margin-right: 30px;
  text-transform: capitalize;
  display: inline-block;
  width: 100%;
}

.paginationBox .next.disabled a {
  color: #e9a5f3;
}

.paginationBox .selected {
  background: #e9a5f3;
  text-align: center;
  box-shadow: 0px 3px 5px 0px #e9a5f34d;
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  margin-right: 30px;
  width: 25px;
  height: 25px;
  display: inline-block;
  line-height: 49px;
}

/* .paginationBox .selected a {
  color: #fff !important;
} */
/*info style start*/
.contentOuter {
  margin: 25px 0px;
  box-shadow: 0px 5px 15px 0px #e9a5f31a;
  background-color: #fff;
  border-radius: 10px;
}

.contentOuter .cke_chrome {
  border: none;
}

.contentOuter .cke_bottom {
  border-top: none !important;
  background-color: #eaa7ef1c !important;
}

.IngredientsList li {
  padding: 10px 25px;
  border-radius: 50px;
  background-color: #fff;
  font-weight: 500;
  margin-right: 10px;
  box-shadow: 0px 5px 20px 0px #e9a5f326;
  margin-bottom: 10px;
  border: 1px solid rgb(233 165 243 / 30%);
}

.IngredientsList li.active {
  border: 1px solid #e9a5f3;
}

.IngredientsList li.active a {
  color: #e9a5f3;
}

.IngredientsList li a {
  font-size: 16px;
  font-weight: 500;
  color: #8a7c8d;
  text-transform: uppercase;
}

.bottomList li {
  margin: 0;
  line-height: 35px;
  position: relative;
  margin-left: 20px;
}

.bottomList li:before {
  position: absolute;
  content: "";
  top: 50%;
  left: -20px;
  width: 9px;
  height: 9px;
  background: #4c4747;
  border-radius: 100%;
  transform: translateY(-50%);
}

.contentOuter .text_ {
  color: #2d2832;
  font-weight: 500;
}

/*setting style start*/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 29px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e2cee5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 8px;
  bottom: 4px;
  background-color: #fff;
  transition: 0.4s;
  box-shadow: -2px 0px 8px 0px #00000040;
}

.switchOuter input:checked+.slider {
  background-color: #e9a5f3;
}

.switchOuter input:focus+.slider {
  box-shadow: 0 0 1px #e9a5f3;
}

.switchOuter input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.middletext {
  max-width: 550px;
}

.inputOuter {
  position: relative;
  margin-right: 23px;
  padding: 20px 0px !important;
}

.inputOuter:last-child {
  margin-right: 0;
}

.lockIcon {
  position: absolute;
  top: 50%;
  transform: translate(-50%);
  left: 0;
}

.inputOuter input {
  min-width: 275px;
  width: 100%;
  color: #8a7c8d;
}

/*add plan page style start*/
.threedot {
  transform: translate(-30px, -20px) !important;
}

/* Neha css START (25 Oct 2023) */
.rightDots {
  position: relative;
  right: 20px;
  bottom: 0;
}

/* Neha css END (25 Oct 2023) */

/* Sidebar.css */
.sidebar {
  width: 250px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: left 0.3s;
  z-index: 1;
}

.SideNavOuter {
  display: flex;
  justify-content: space-between;
  width: 250px;
  height: 92vh;
}

.sidebar.open {
  left: 0;
}

.sideheader {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.sidebar-content {
  padding: 20px;
  color: #fff;
}

.toggle-button {
  position: fixed;
  top: 33px;
  right: 12px;
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
  color: #e9a5f3;
  border-radius: 12px;
  font-size: 21px;
}
/* priyanka css start */
.attachPdf .searchIcon {
  top: 14px;
}
.attachPdf .recipeImg img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.detailRecipe .detail-recipe-outer .details-heading-recipe {
  width: 76%;
}
.detailRecipe .detail-recipe-outer .details-heading-recipe .courseHeading {
  word-break: break-all;
}
.attachPdf .addRecipeBox .recipeImgOuter .recipeImg {
  width: 54px;
  height: 54px;
  max-width: initial;
}
@media (max-width: 1280px) {
  .detailRecipe .detail-recipe-outer .details-heading-recipe {
    width: 70%;
}
}
@media (max-width: 1072px) {
.detailRecipe .detail-recipe-outer .details-heading-recipe {
  width: 61%;
}
}
@media (max-width: 853px) {
.detailRecipe .detail-recipe-outer .details-heading-recipe {
  width: 53%;
}
}
@media (max-width: 582px) {
  .detailRecipe .detail-recipe-outer {
    flex-wrap: wrap;
}
  .detailRecipe .detail-recipe-outer .details-heading-recipe {
    width: 100%;
}
.editDeleteBtn .editBtn {
  padding: 8px 25px 7px 26px;
  font-size: 13px;

}
}
@media (max-width: 480px) {
.detailRecipe .detail-recipe-outer .details-heading-recipe {
  width: 100%;
}
}
/* Media query for screens 576px or less */
@media (max-width: 767px) {
  .sidebar {
    left: -250px;
  }

  .sidebar.open {
    left: 0;
  }

  .sidebar-content {
    display: none;
  }

  .toggle-button {
    display: block;
  }
}

.graphCon thead {
  /* position: sticky; */
  top: -16px;
  width: 100%;
}

/* .graphCon .tableScrollBody{
  height: 100%;
  max-height: 500px;
  overflow: auto;
} */

/* abhishek css start */

.imagePreview {
  padding-top: 45px !important;
  padding: 0;
}

/* abhishek css end */
.courseCardConRecipe .courseDurationDetials {
	margin-top: 18px;
}
.courseCardConRecipe .courseDurationDetials li p img {
  width: 18px;
}
.AddClass .IngredientsBoxInner .classuser {
  width: 23px;
}
.AddClass .IngredientsBoxInner .medText {
  text-transform: capitalize;
}
.addRecipeBoxInner .choosenBtn {
  cursor: pointer;
}
.trashicon {
  cursor: pointer;
}
.addClassCross-resp {

  cursor: pointer;
}
.infotab_ li a {
  text-transform: capitalize;
}
.insidecontent_ .paginationBox .selected {
	line-height: 27px;
}
.inrecipeout {
	width: 16%;
}
.contentCommon.insidecontent_ .toptextOuter p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
@media (max-width: 480px) {
  .courseCardConRecipe .courseDrpodown {
    transform: translate(4px, -32px) !important;
  }
}