/* Start Common Css */
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
body{
    font-family: 'Poppins', sans-serif;
}
a{
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
}
ul li{
    list-style: none;
    display: inline-block;
}
.heading{
    font-size: 34px;
    font-weight: 600;
    color: #2D2832;
}
.fw-500{
    font-weight: 500;
}
.subtext {
    font-size: 12px;
    font-weight: 400;
    color: #8A7C8D;
    line-height: 22px;
    font-family: 'Open Sans', sans-serif;
    word-break: break-all;
}

/*Start Input field after fill background color css */
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px  rgba(252, 243, 253, 0.7) inset !important;
}
/*End Input field after fill background color css */
.subtext:hover{
    color: #8A7C8D;
}
.colorpink{
    color:  #E9A5F3;
    display: flex;
    align-items: center;
    font-size: 14px;
}
.colorpink:hover{
    color:  #E9A5F3;
}
.dflex{
    display: flex;
}
.justify-between{
    justify-content: space-between;
}
.item-center{
    align-items: center;
}

/* End Common Css */

/*login page style start*/
.loginForm {
    padding: 50px 0px;
}
.loginForm .form-group {
    position: relative;
}

.loginForm .form-control {
    border: none;
    padding: 19.5px 45px 19.5px 47px;
    font-size: 14px;
    background: rgba(252, 243, 253, 0.7);
    border-radius: 50px;
    color: rgba(138, 124, 141, 0.6) !important;
    
}
.loginForm .form-control:focus {
    border: none;
    background: #fff;
    box-shadow: 0px 4px 25px 0px #E9A5F326;
}
.icon_ {
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translateY(-50%);
}
.lockIcon {
    position: absolute;
    left: 20px;
}
.login-btn{
    background: #E9A5F3;
    border-radius: 50px;
    padding: 13px 31px;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    font-family:'Poppins', sans-serif;
}
.editProfileBtn{
    padding: 13px 31px !important;
}
.sendButton{
    padding: 13px 31px !important;
}
.login_create .sendButton {
    padding: 17px 44px !important;
}
.StartStremBtn{
    font-size: 13px;
    padding: 13px 16px;
}
.StartStremCircle{
    padding: 8px 11px !important;
}
.StartStremCircle img{
    width: 15px !important;
}
.forgotPw {
    position: relative;
    bottom: 8px;
}
.forgotPara {
    padding-left: 3px;
}
.signup{
    padding-left: 8px;
}
.login_create .circle-btn {
    padding: 15px;
}

.circle-btn {
    background-color: #E9A5F3;
    border-radius: 50%;
    padding: 13px 16px;
    margin-left: -3px;
    cursor: pointer;
    border: 1px solid #fff;
}
.whiteRec {
    width: 30px;
    height: 12px;
    background-color: #fff;
    box-shadow: 4px 6px 17px #dddddda6;
    filter: drop-shadow(0px -1px 34px rgba(233, 165, 243, 0.70));
}

.resendText{
    cursor: default;
}

.center-img{
    margin-left: -3px;
}
.logininner {
    padding: 15px 50px 0 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}
.imgouter {
    position: relative;
    display: flex;
    height: 100vh;
    justify-content: flex-end;
}
.imgouter:before {
    position: absolute;
    top: 48%;
    background: #020300;
    width: 25px;
    height: 25px;
    left: -10px;
    content: "";
    /* transform: translateY(-50%); */
    transform: rotate(45deg);
}
.form-check-input {
    background: #FFFFFF;
    border: 1px solid rgba(189, 182, 190, 0.2);
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.form-check-input:checked {
    background-color: #eaa7ef;
    border-color: #eaa7ef;
}
.form-check-input:focus{
    box-shadow: none;
    border-color: #eaa7ef;
}


/*forgot password page style start*/
.backicon {
    padding-right: 8px;
}
/*forgot password page style end*/
.otpBox input {
    width: 58px !important;
    height: 58px;
    border-radius: 100% !important;
    background: rgba(252, 243, 253, 0.7);
    border: none;
    outline: none;
    transition: all 0.3s ease-in-out;
    margin-right: 16px;
}
.otpBox input.focus-visible {
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(233, 165, 243, 0.25);
    border: none;
}
.otpBox input:active{
    background: #FFFFFF;
    box-shadow: 0px 4px 15px rgba(233, 165, 243, 0.25);
    border: none;
}

.inputIcon {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
}
.correct_password{
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #20A140;
}
.correctIcon{
    margin-right: 10px;
}
.incorrect_password {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #A29FB4;
}
.incorrectIcon{
    margin-right: 10px;
}
.alreadyBooked{
    background: rgb(59, 66, 59);
    border-radius: 50px;
    padding: 13px 31px;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    font-family:'Poppins', sans-serif;
}
.cancelBooking{
    background: rgb(253, 250, 251);
    border-radius: 50px;
    padding: 13px 31px;
    border: none;
    color: #491a1a;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

@media only screen and  (min-width:992px) and (max-width:1200px){
}
@media (max-width:991px){
    .imgouter{
        display: none;
    }
}
